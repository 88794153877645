import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import useGetPublication from '../../hooks/useGetPublication/useGetPublication';
import useLocalStorage from '../../hooks/useLocalStorage/useLocalStorage';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';
import Spinner from '../../components/Spinner/Spinner';

import { LOCAL_STORAGE_KEYS } from '../../config/constants/contants';

import placeholderImage from '../../images/placeholder.jpg';

import styles from './PublicationPage.module.scss';
import commons from '../../shared/styles/common.module.scss';

const PublicationPage = () => {
  const { t } = useTranslation();

  const { getLocalStorageItem } = useLocalStorage();

  const postId = getLocalStorageItem(LOCAL_STORAGE_KEYS.PUBLICATION_ID);

  const { data, refetch } = useGetPublication(Number(postId), false);

  useSetPageTitle({
    pageTitle: `${data?.title} - ${t('general.website-name')}`,
  });

  React.useEffect(() => {
    if (postId) {
      refetch();
    }
  }, [postId]);

  return !data ? (
    <Spinner variant='green' />
  ) : (
    <div>
      <PageBanner
        title={data.title}
        imageSrc={data.featured_image || placeholderImage}
        imageText={data.title}
      />
      <div
        className={classNames(styles.publicationContainer, commons.container)}>
        <div>
          <h1
            className={classNames(
              styles.publicationTitle,
              commons.subSectionHeading,
            )}>
            {data.title}
          </h1>
          <div className={styles.publicationContent}>{parse(data.content)}</div>
          {data.featured_image && (
            <img
              src={data.featured_image}
              alt={data.title}
              className={styles.publicationContentImage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicationPage;
