import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetExhibitionResponse } from '../../types/TGetExhibitionResponse';

const useGetExhibition = (id: number, shouldQuery: boolean) => {
  const { data, isLoading, error, refetch } = request<TGetExhibitionResponse>(
    `${BASE_URL}/api/v1/exhibition/${id}`,
    QueryKeys.ExhibitionItem,
    shouldQuery,
  );

  return { data, isLoading, error, refetch };
};

export default useGetExhibition;
