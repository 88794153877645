import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import useGetExhibition from '../../hooks/useGetExhibition/useGetExhibition';
import useLocalStorage from '../../hooks/useLocalStorage/useLocalStorage';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import Icon from '../../components/Icon/Icon';
import PageBanner from '../../common/PageBanner/PageBanner';
import Spinner from '../../components/Spinner/Spinner';

import { LOCAL_STORAGE_KEYS } from '../../config/constants/contants';

import styles from './ExhibitionPage.module.scss';
import commons from '../../shared/styles/common.module.scss';

const ExhibitionPage = () => {
  const { t } = useTranslation();
  const { getLocalStorageItem } = useLocalStorage();

  const postId = getLocalStorageItem(LOCAL_STORAGE_KEYS.EXHIBITION_ID);

  const { data, refetch } = useGetExhibition(Number(postId), false);

  useSetPageTitle({
    pageTitle: `${data?.title} - ${t('general.website-name')}`,
  });

  React.useEffect(() => {
    if (postId) {
      refetch();
    }
  }, [postId]);

  return !data ? (
    <Spinner variant='green' />
  ) : (
    <div>
      <PageBanner
        title={data.title}
        imageSrc={data.featured_image}
        imageText={data.title}
      />
      <div
        className={classNames(styles.exhibitionContainer, commons.container)}>
        <div>
          <h1
            className={classNames(
              styles.exhibitionTitle,
              commons.subSectionHeading,
            )}>
            {data.title}
          </h1>
          <div className={styles.exhibitionContent}>{parse(data.content)}</div>
          <img
            src={data.featured_image}
            alt={data.title}
            className={styles.exhibitionContentImage}
          />
        </div>
        <div className={styles.exhibitionDetails}>
          <p className={styles.exhibitionDetailsItem}>
            <span className={styles.exhibitionDetailsItemText}>
              <Icon name='fa-calendar' color='icon-accent' />
              <span>{t('general.date')}:</span>
            </span>
            <span className={styles.exhibitionDetailsItemContent}>
              {data.dates.start_date && <span>{data.dates.start_date}</span>}
              {data.dates.end_date && (
                <>
                  <span
                    className={styles.exhibitionDetailsItemContentSeparator}>
                    -
                  </span>
                  <span>{data.dates.end_date}</span>
                </>
              )}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionPage;
