import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import commons from '../../shared/styles/common.module.scss';
import styles from './AboutMuseum.module.scss';

const AboutMuseum = () => {
  const { t } = useTranslation();

  const imagesURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  useSetPageTitle({
    pageTitle: `${t('about.title')} - ${t('general.website-name')}`,
  });

  return (
    <div>
      <PageBanner
        imageSrc={`${imagesURL}${UPLOADS_FOLDER}/cetatea-iarna.jpg`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('about.title')}
        subtitle={t('about.subtitle')}
      />
      <div className={classNames(styles.content, commons.container)}>
        <div className={styles.contentWithImage}>
          <div>
            <p>
              <Trans
                i18nKey='about.content.line-one'
                components={{ i: <i /> }}
              />
            </p>
            <p>
              <Trans i18nKey='about.content.line-two' />
            </p>
            <p>
              <Trans i18nKey='about.content.line-three' />
            </p>
            <p>
              <Trans i18nKey='about.content.line-four' />
            </p>
            <p>
              <Trans i18nKey='about.content.line-five' />
            </p>
            <div className={styles.photoGallery}>
              <div>
                <img
                  src={`${imagesURL}${UPLOADS_FOLDER}/cetatea-fagaras-vechi.webp`}
                  alt='Muzeul Tării Făgărașului'
                  loading='lazy'
                />
                <img
                  src={`${imagesURL}${UPLOADS_FOLDER}/cetatea-fagaras-sala-dietei.jpeg`}
                  alt='Muzeul Tării Făgărașului'
                  loading='lazy'
                />
              </div>
              <img
                src={`${imagesURL}${UPLOADS_FOLDER}/cetatea-fagaras-sala-tronului.jpeg`}
                alt='Muzeul Tării Făgărașului'
                loading='lazy'
              />
            </div>
          </div>
          <img
            src={`${imagesURL}${UPLOADS_FOLDER}/valer-literat.jpeg`}
            alt='Valer Literat'
          />
        </div>
      </div>
    </div>
  );
};

export default AboutMuseum;
