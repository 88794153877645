import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';
import Icon from '../../components/Icon/Icon';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  PHONE_NUMBER,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import cardImage from '../../images/vitralii-new.webp';

import commons from '../../shared/styles/common.module.scss';
import styles from './Contact.module.scss';

const Contact = () => {
  const { t } = useTranslation();

  const bannerURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  useSetPageTitle({
    pageTitle: `${t('contact.title')} - ${t('general.website-name')}`,
  });

  return (
    <div>
      <PageBanner
        imageSrc={`${bannerURL}${UPLOADS_FOLDER}/cetatea-fagaras-sala-dietei.jpeg`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('contact.title')}
        subtitle={t('contact.subtitle')}
      />
      <div className={classNames(styles.content, commons.container)}>
        <div>
          <p>
            <Trans
              i18nKey='contact.content.line-one'
              components={{ b: <b /> }}
            />
          </p>
          <p>
            <Trans
              i18nKey='contact.content.line-two'
              components={{
                a: (
                  <a href='/politica-de-confidentialitate'>
                    {t('footer.links.privacy-policy')}
                  </a>
                ),
              }}
              values={{ phoneNumber: PHONE_NUMBER }}
            />
          </p>
          <p>
            <Trans
              i18nKey='contact.content.line-three'
              components={{
                a: <a href='https://www.facebook.com/muzeufagaras'>Facebook</a>,
              }}
              values={{ phoneNumber: PHONE_NUMBER }}
            />
          </p>
        </div>
      </div>
      <div className={styles.gridContent}>
        <div
          className={classNames(
            styles.contentSquare,
            styles.customContentSquare,
          )}>
          <img src={cardImage} alt={DEFAULT_IMAGE_ALT_TEXT} loading='lazy' />
          <div>
            <p>Contact</p>
            <p>Muzeul Țării Făgărașului Valer Literat</p>
          </div>
        </div>
        <div className={styles.contentSquare}>
          <Icon name='fa-location-dot' color='icon-gray' size={30} />
          <p>{t('contact.content.address.label')}</p>
          <p>{t('contact.content.address.value')}</p>
        </div>
        <div className={styles.contentSquare}>
          <Icon name='fa-phone' color='icon-gray' size={30} />
          <p>{t('contact.content.phone')}</p>
          <p>0368 404 287 | 0739 084 056</p>
        </div>
        <div className={styles.contentSquare}>
          <Icon name='fa-envelope' color='icon-gray' size={30} />
          <p>{t('contact.content.email')}</p>
          <p>muzeufagaras@yahoo.com</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
