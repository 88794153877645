import React from 'react';
import { useTranslation } from 'react-i18next';

import CookieConsentComponent from '../../components/CookieConsent/CookieConsent';
import CookieSettings from '../../components/CookieSettings/CookieSettings';

const CookiesWrapper = () => {
  const { t } = useTranslation();

  const [isSettingsModalOpen, setIsSettingsModalOpen] =
    React.useState<boolean>(false);
  const overlayTag = document.getElementsByClassName('overlay')[0];
  const bodyTag = document.getElementsByTagName('body')[0];

  const handleOpenSettings = () => {
    setIsSettingsModalOpen(true);
    overlayTag.classList.add('overlayActive');
    bodyTag.style.overflow = 'hidden';
  };

  const handleCloseSettings = () => {
    setIsSettingsModalOpen(false);
    overlayTag.classList.remove('overlayActive');
    bodyTag.style.overflow = 'visible';
  };

  const handleModalSubmit = () => {
    const bannerAcceptButton = document.getElementById(
      'rcc-confirm-button',
    ) as HTMLButtonElement;
    handleCloseSettings();
    bannerAcceptButton.click();
  };

  return (
    <div>
      <CookieConsentComponent onOpenSettings={handleOpenSettings} />
      <CookieSettings
        isModalOpen={isSettingsModalOpen}
        primaryButton={{
          label: t('cookies-banner.buttons.save'),
        }}
        secondaryButton={{
          label: t('cookies-banner.buttons.close'),
        }}
        title={t('cookies-banner.presentation')}
        onCloseModal={handleCloseSettings}
        onSubmitModal={handleModalSubmit}
      />
    </div>
  );
};

export default CookiesWrapper;
