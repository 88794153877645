import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetYearbookByYear } from '../../types/TGetYearbookByYear';

const useGetYearbooksByYear = (year: string) => {
  const { data, isLoading, error, refetch } = request<TGetYearbookByYear>(
    `${BASE_URL}/api/v1/yearbooks/${year}`,
    QueryKeys.YearbooksByYear,
    false,
  );

  return { data, isLoading, error, refetch };
};

export default useGetYearbooksByYear;
