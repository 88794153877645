import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import useGetRecommendedArticle from '../../hooks/useGetRecommendedArticle/useGetRecommendedArticle';
import useLocalStorage from '../../hooks/useLocalStorage/useLocalStorage';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';
import Spinner from '../../components/Spinner/Spinner';

import { LOCAL_STORAGE_KEYS } from '../../config/constants/contants';

import styles from './RecommendedArticle.module.scss';
import commons from '../../shared/styles/common.module.scss';

const RecommendedArticle = () => {
  const { t } = useTranslation();
  const { getLocalStorageItem } = useLocalStorage();

  const postId = getLocalStorageItem(LOCAL_STORAGE_KEYS.RECOMMENDED_ID);

  const { data, refetch } = useGetRecommendedArticle(Number(postId), false);

  useSetPageTitle({
    pageTitle: `${data?.title} - ${t('general.website-name')}`,
  });

  React.useEffect(() => {
    if (postId) {
      refetch();
    }
  }, [postId]);

  return !data ? (
    <Spinner variant='green' />
  ) : (
    <div>
      <PageBanner
        title={data.title}
        imageSrc={data.featured_image}
        imageText={data.title}
      />
      <div className={classNames(styles.newsItemContainer, commons.container)}>
        <div>
          <h1
            className={classNames(
              styles.newsItemTitle,
              commons.subSectionHeading,
            )}>
            {data.title}
          </h1>
          <div className={styles.newsItemContent}>{parse(data.content)}</div>
          <img
            src={data.featured_image}
            alt={data.title}
            className={styles.newsItemContentImage}
          />
        </div>
      </div>
    </div>
  );
};

export default RecommendedArticle;
