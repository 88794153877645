import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetPublicationsResponse } from '../../types/TGetPublicationsResponse';
import { TSupportedLanguages } from '../../types/TSupportedLanguages';

const useGetPublications = (
  offset: number,
  language: TSupportedLanguages,
  page: number,
) => {
  const { data, isLoading, error, refetch } = request<TGetPublicationsResponse>(
    `${BASE_URL}/api/v1/publications?offset=${offset}&language=${language}&page=${page}`,
    QueryKeys.Publications,
    true,
    page,
  );

  return { data, isLoading, error, refetch };
};

export default useGetPublications;
