import classNames from 'classnames';

import useLocalStorage from '../../../hooks/useLocalStorage/useLocalStorage';

import { TPublication } from '../../../types/TGetPublicationsResponse';

import placeholderImage from '../../../images/placeholder.jpg';

import commons from '../../../shared/styles/common.module.scss';
import styles from './PublicationItem.module.scss';
import { LOCAL_STORAGE_KEYS } from '../../../config/constants/contants';

type TProps = {
  publicationItem: TPublication;
};

const PublicationItem = ({ publicationItem }: TProps) => {
  const { setLocalStorageItem } = useLocalStorage();

  const savePostIdInLocalStorage = (id: number, link: string) => {
    setLocalStorageItem(LOCAL_STORAGE_KEYS.PUBLICATION_ID, String(id));
    window.location.href = link;
  };

  return (
    <div
      className={classNames(
        styles.publicationItemContainer,
        commons.container,
      )}>
      <div className={styles.publicationItemContent}>
        <a
          key={publicationItem.id}
          href={publicationItem.link}
          onClick={(e) => {
            e.preventDefault();
            savePostIdInLocalStorage(publicationItem.id, publicationItem.link);
          }}
          className={styles.publicationItemWrapper}>
          <div className={styles.publicationItemText}>
            <h4 className={styles.publicationItemTitle}>
              {publicationItem.title}
            </h4>
            <p className={styles.publicationItemExcerpt}>
              {publicationItem.excerpt}
            </p>
          </div>
          <img
            src={publicationItem.featured_image || placeholderImage}
            alt={publicationItem.title}
            className={styles.publicationItemImage}
          />
        </a>
      </div>
    </div>
  );
};

export default PublicationItem;
