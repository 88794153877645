import React from 'react';
import classNames from 'classnames';

import BurgerMenu from '../BurgerMenu/BurgerMenu';
import Menu from '../Menu/Menu';

import { getLanguage } from '../../helpers/helpers';

import { mainMenu } from '../../config/constants/menus';
import {
  DEV_BASE_URL,
  IS_DEV,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import styles from './Header.module.scss';

const Header = () => {
  const [isBurgerMenuToggled, setIsBurgerMenuToggled] =
    React.useState<boolean>(false);

  const logoURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  return (
    <header className={styles.headerModule}>
      <div
        className={classNames(styles.headerItemsContainer, {
          [styles.burgerMenuToggled]: isBurgerMenuToggled,
        })}>
        <div className={styles.headerLogo}>
          <a href='/'>
            <img
              src={`${logoURL}${UPLOADS_FOLDER}/logo-dark.png`}
              alt='Logo'
              data-testid='website-logo'
              loading='lazy'
            />
          </a>
        </div>
        <div className={styles.headerMenu}>
          <BurgerMenu
            isBurgerMenuToggled={isBurgerMenuToggled}
            onBurgerMenuToggle={() => setIsBurgerMenuToggled((prev) => !prev)}
          />
          <Menu
            items={mainMenu[getLanguage()]}
            isBurgerMenuToggled={isBurgerMenuToggled}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
