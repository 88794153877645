import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Icon from '../../../../components/Icon/Icon';

import styles from '../../Visiting.module.scss';
import commons from '../../../../shared/styles/common.module.scss';

const Amenities = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.pageContentContainer, commons.container)}>
      <div className={styles.leftSideContent}>
        <h1 className={styles.sectionHeading}>{t('visiting.facilities')}</h1>
        <p>{t('visiting.facilities-details-line-one')}</p>
        <p>{t('visiting.facilities-details-line-two')}</p>
        <p>{t('visiting.facilities-details-line-three')}</p>
      </div>
      <div className={classNames(styles.rightSideContent, styles.gridContent)}>
        <div className={styles.contentSquare}>
          <Icon name='fa-info' color='icon-accent' size={30} />
          <p className={styles.contentLinePlain}>
            {t('visiting.facilities-list.information-desk')}
          </p>
        </div>
        <div className={styles.contentSquare}>
          <Icon name='fa-wifi' color='icon-accent' size={30} />
          <p className={styles.contentLinePlain}>
            {t('visiting.facilities-list.free-wifi')}
          </p>
        </div>
        <div className={styles.contentSquare}>
          <Icon name='fa-restroom' color='icon-accent' size={30} />
          <p className={styles.contentLinePlain}>
            {t('visiting.facilities-list.restroom')}
          </p>
        </div>
        <div className={styles.contentSquare}>
          <Icon name='fa-car' color='icon-accent' size={30} />
          <p className={styles.contentLinePlain}>
            {t('visiting.facilities-list.parking')}
          </p>
        </div>
        <div className={styles.contentSquare}>
          <Icon name='fa-camera' color='icon-accent' size={30} />
          <p className={styles.contentLinePlain}>
            {t('visiting.facilities-list.photography')}
          </p>
        </div>
        <div className={styles.contentSquare}>
          <Icon name='fa-gears' color='icon-accent' size={30} />
          <p className={styles.contentLinePlain}>
            {t('visiting.facilities-list.others')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Amenities;
