import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';

import { GeneralDataProvider } from './contexts/useGeneralDataContext/useGeneralDataContext';

import App from './App';
import Header from './common/Header/Header';
import Footer from './common/Footer/Footer';
import CookiesWrapper from './common/CookiesWrapper/CookiesWrapper';

import i18n from './config/i18n/i18n';
import { queryClientConfig } from './config/constants/react-query';
import { LOCAL_STORAGE_KEYS } from './config/constants/contants';

import reportWebVitals from './reportWebVitals';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const queryClient = new QueryClient(queryClientConfig);

const html = document.getElementsByTagName('html')[0] as HTMLElement;
const selectedLanguageInStorage = localStorage.getItem(
  LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE,
);
html.setAttribute('lang', selectedLanguageInStorage || 'ro-RO');

if (!selectedLanguageInStorage) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE, 'ro-RO');
}

if (root) {
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <GeneralDataProvider>
            <Header />
            <App />
            <Footer />
            <CookiesWrapper />
          </GeneralDataProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
