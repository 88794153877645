import { BrowserRouter, Routes as DOMRoutes, Route } from 'react-router-dom';

import AboutMuseum from '../pages/AboutMuseum/AboutMuseum';
import Contact from '../pages/Contact/Contact';
import Contests from '../pages/PublicInterest/Contests/Contests';
import Cookies from '../pages/PublicInterest/Cookies/Cookies';
import EventPage from '../pages/EventPage/EventPage';
import Events from '../pages/Events/Events';
import ExhibitionPage from '../pages/ExhibitionPage/ExhibitionPage';
import Exhibitions from '../pages/Exhibitions/Exhibitions';
import ForeignTravellers from '../pages/ForeignTravellers/ForeignTravellers';
import FortressHistory from '../pages/FortressHistory/FortressHistory';
import FortressMessengers from '../pages/FortressMessengers/FortressMessengers';
import FortressWeapons from '../pages/FortressWeapons/FortressWeapons';
import FourOFourPage from '../pages/404/404';
import Home from '../pages/Home/Home';
import MonthlyExhibitPage from '../pages/MonthlyExhibitPage/MonthlyExhibitPage';
import News from '../pages/News/News';
import NewsPage from '../pages/NewsPage/NewsPage';
import PrivacyPolicy from '../pages/PublicInterest/PrivacyPolicy/PrivacyPolicy';
import PublicationPage from '../pages/PublicationPage/PublicationPage';
import Publications from '../pages/Publications/Publications';
import RecommendedArticle from '../pages/RecommendedArticle/RecommendedArticle';
import SalaryRights from '../pages/PublicInterest/SalaryRights/SalaryRights';
import TermsAndConditions from '../pages/PublicInterest/TermsAndConditions/TermsAndConditions';
import QrCode from '../pages/QrCodes/QrCode';
import VirtualTour from '../pages/VirtualTour/VirtualTour';
import Visiting from '../pages/Visiting/Visiting';
import WealthDeclaration from '../pages/PublicInterest/WealthDeclaration/WealthDeclaration';
import Yearbook from '../pages/Yearbook/Yearbook';
import YearbookByYear from '../pages/YearbookByYear/YearbookByYear';

import { routes } from '../config/constants/routes';
import { getLanguage } from '../helpers/helpers';

import { AvailableLanguages } from '../interfaces/IAvailableLanguages';

const Routes = () => {
  const currentLanguage = getLanguage();

  const routesPath = routes[`${currentLanguage}` as keyof AvailableLanguages];

  return (
    <BrowserRouter>
      <DOMRoutes>
        {/** Main pages */}
        <Route path={routesPath.home} element={<Home />} />
        <Route path={routesPath.visiting} element={<Visiting />} />
        <Route path={routesPath.about} element={<AboutMuseum />} />
        <Route path={routesPath.events} element={<Events />} />
        <Route path={routesPath.news} element={<News />} />
        <Route path={routesPath.exhibitions} element={<Exhibitions />} />
        <Route path={routesPath.publications} element={<Publications />} />
        <Route path={routesPath.actaTerrae} element={<Yearbook />} />
        <Route
          path={routesPath.actaTerraeDetail}
          element={<YearbookByYear />}
        />
        <Route path={routesPath.contact} element={<Contact />} />
        <Route path={routesPath.virtualTour} element={<VirtualTour />} />

        {/** Detail pages */}
        <Route
          path={routesPath.monthlyExhibit}
          element={<MonthlyExhibitPage />}
        />
        <Route path={routesPath.eventPage} element={<EventPage />} />
        <Route path={routesPath.newsPage} element={<NewsPage />} />
        <Route path={routesPath.newsPage} element={<NewsPage />} />
        <Route path={routesPath.exhibitionPage} element={<ExhibitionPage />} />
        <Route
          path={routesPath.publicationPage}
          element={<PublicationPage />}
        />
        <Route
          path={routesPath.recommendedArticle}
          element={<RecommendedArticle />}
        />
        <Route
          path={routesPath.fortressHistory}
          element={<FortressHistory />}
        />
        <Route
          path={routesPath.foreignTravellers}
          element={<ForeignTravellers />}
        />
        <Route
          path={routesPath.fortressWeapons}
          element={<FortressWeapons />}
        />
        <Route
          path={routesPath.fortressMessengers}
          element={<FortressMessengers />}
        />

        {/** Public interest pages */}
        <Route
          path={routesPath.wealthDeclaration}
          element={<WealthDeclaration />}
        />
        <Route path={routesPath.salaryRights} element={<SalaryRights />} />
        <Route path={routesPath.contests} element={<Contests />} />
        <Route path={routesPath.privacyPolicy} element={<PrivacyPolicy />} />
        <Route
          path={routesPath.termsAndConditions}
          element={<TermsAndConditions />}
        />
        <Route path={routesPath.cookies} element={<Cookies />} />

        {/** QR Codes pages */}
        <Route path={routesPath.qrCode} element={<QrCode />} />

        {/** Error pages */}
        <Route path='*' element={<FourOFourPage />} />
      </DOMRoutes>
    </BrowserRouter>
  );
};

export default Routes;
