import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import Button from '../../components/Button/Button';
import PageBanner from '../../common/PageBanner/PageBanner';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import styles from './404.module.scss';
import commons from '../../shared/styles/common.module.scss';

const FourOFourPage = () => {
  const { t } = useTranslation();

  const bannerURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  useSetPageTitle({
    pageTitle: `${t('404.title')} - ${t('general.website-name')}`,
  });

  return (
    <div>
      <PageBanner
        imageSrc={`${bannerURL}${UPLOADS_FOLDER}/cetatea-fagaras-sala-dietei.jpeg`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
      />
      <div className={classNames(styles.content, commons.container)}>
        <h1 className={classNames(styles.pageTitle, commons.subSectionHeading)}>
          {t('404.title')}
        </h1>
        <h2 className={styles.pageSubtitle}>{t('404.subtitle')}</h2>
        <Button
          variant='primary'
          label={t('404.cta')}
          onClick={() => window.location.replace('/')}
          className={styles.pageCTA}
        />
      </div>
    </div>
  );
};

export default FourOFourPage;
