import { useTranslation } from 'react-i18next';

import Icon from '../Icon/Icon';

import styles from './CookieSettingsContent.module.scss';

type TProps = {
  isMoreInfoVisible: boolean;
  onToggleMoreInformation: () => void;
};

const CookieSettingsContent = ({
  isMoreInfoVisible,
  onToggleMoreInformation,
}: TProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.cookieSettingsContent}>
      <div>
        <p>{t('cookies-banner.content.line-one')}</p>
        <p>{t('cookies-banner.content.line-two')}</p>
      </div>
      <div>
        <div
          onClick={onToggleMoreInformation}
          className={styles.moreInformationToggler}>
          <span className={styles.moreInformationArrow}>
            <Icon
              name={`fa-solid ${
                isMoreInfoVisible ? 'fa-chevron-down' : 'fa-chevron-right'
              }`}
              size={12}
              color='icon-accent'
            />
            <span>{t('cookies-banner.necessary.cookies')}</span>
          </span>
          <span className={styles.moreInformationCaption}>
            {t('cookies-banner.necessary.always-activated')}
          </span>
        </div>
        {isMoreInfoVisible && (
          <div className={styles.moreInformationWrapper}>
            <p>{t('cookies-banner.necessary.description')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CookieSettingsContent;
