import classNames from 'classnames';
import parse from 'html-react-parser';

import PageBanner from '../../common/PageBanner/PageBanner';
import Spinner from '../../components/Spinner/Spinner';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import commons from '../../shared/styles/common.module.scss';
import styles from './QrCode.module.scss';
import useGetQrCodePage from '../../hooks/useGetQrCodePage/useGetQrCodePage';

const QrCode = () => {
  const pathId = window.location.pathname.split('-q-')[1];
  const imagesURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  const { data, isLoading } = useGetQrCodePage(Number(pathId), true);

  return (
    <div>
      <PageBanner
        imageSrc={`${imagesURL}${UPLOADS_FOLDER}/placeholder.jpg`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={data?.title}
      />
      <div className={classNames(styles.content, commons.container)}>
        {isLoading ? (
          <Spinner variant='green' />
        ) : (
          data && (
            <div className={styles.contentValue}>{parse(data.content)}</div>
          )
        )}
      </div>
    </div>
  );
};

export default QrCode;
