import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { encodeb64String } from '../../helpers/helpers';

import { TGetPostsResponse } from '../../types/TGetPostsResponse';
import { TSupportedLanguages } from '../../types/TSupportedLanguages';

const useGetPosts = (categoryId: string, language: TSupportedLanguages) => {
  const { data, isLoading, error, refetch } = request<TGetPostsResponse>(
    `${BASE_URL}/api/v1/posts?filter=${encodeb64String(
      `category=${categoryId}&language=${language}`,
    )}`,
    QueryKeys.FooterPosts,
    true,
  );

  return { data, isLoading, error, refetch };
};

export default useGetPosts;
