import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from '../../Visiting.module.scss';
import commons from '../../../../shared/styles/common.module.scss';

const Tickets = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.pageContentContainer, commons.container)}>
      <div className={styles.leftSideContent}>
        <h1 className={styles.sectionHeading}>
          {t('visiting.tickets-and-prices')}
        </h1>
        <p>{t('visiting.tickets-and-prices-details-line-one')}</p>
        <p>{t('visiting.tickets-and-prices-details-line-two')}</p>
      </div>
      <div className={styles.rightSideContent}>
        <div className={styles.contentLine}>
          <p className={styles.contentLineEmphasized}>
            {t('visiting.tickets-categories.adults.value')}
          </p>
          <p className={styles.contentLinePlain}>
            {t('visiting.tickets-categories.adults.label')}
          </p>
        </div>
        <div className={styles.contentLine}>
          <div className={styles.contentLineEmphasized}>
            {t('visiting.tickets-categories.students-and-pupils.value')}
          </div>
          <p className={styles.contentLinePlain}>
            {t('visiting.tickets-categories.students-and-pupils.label')}
          </p>
        </div>
        <div className={styles.contentLine}>
          <div className={styles.contentLineEmphasized}>
            {t('visiting.tickets-categories.retirees.value')}
          </div>
          <p className={styles.contentLinePlain}>
            {t('visiting.tickets-categories.retirees.label')}
          </p>
        </div>
        <div className={styles.contentLine}>
          <div className={styles.contentLineEmphasized}>
            {t('visiting.tickets-categories.others.value')}
          </div>
          <p className={styles.contentLinePlain}>
            {t('visiting.tickets-categories.others.label')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
