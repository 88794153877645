import classNames from 'classnames';
import { TSkeletonColors } from './colors';

import styles from './Skeleton.module.scss';

type TProps = {
  backgroundColor?: TSkeletonColors;
  className?: string;
  rows: number;
  testId?: string;
  repeat?: number;
};

const Skeleton = ({
  rows,
  backgroundColor,
  className,
  testId,
  repeat = 1,
}: TProps) => {
  return (
    <div>
      {[...Array(repeat)].map((_, index: number) => (
        <div
          key={index}
          className={classNames(styles.skeletonModule, className)}
          style={{
            height: `${rows}px`,
            backgroundColor,
          }}
          data-testid={`${testId}-${index}`}
        />
      ))}
    </div>
  );
};

export default Skeleton;
