import React from 'react';

import Modal from '../../common/Modal/Modal';

import CookieSettingsContent from './CookieSettingsContent';

type TProps = {
  isModalOpen: boolean;
  primaryButton: {
    label: string;
  };
  secondaryButton: {
    label: string;
  };
  title: string;
  onCloseModal: () => void;
  onSubmitModal: () => void;
};

const CookieSettings = ({
  isModalOpen,
  primaryButton,
  secondaryButton,
  title,
  onCloseModal,
  onSubmitModal,
}: TProps) => {
  const [isMoreInfoVisible, setIsMoreInfoVisible] =
    React.useState<boolean>(false);

  const handleToggleMoreInformation = () =>
    setIsMoreInfoVisible((prev) => !prev);

  return (
    <Modal
      isOpen={isModalOpen}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      title={title}
      onClose={onCloseModal}
      onSubmit={onSubmitModal}>
      <CookieSettingsContent
        isMoreInfoVisible={isMoreInfoVisible}
        onToggleMoreInformation={handleToggleMoreInformation}
      />
    </Modal>
  );
};

export default CookieSettings;
