import classNames from 'classnames';

import useLocalStorage from '../../../hooks/useLocalStorage/useLocalStorage';

import { TEvent } from '../../../types/TGetEventsResponse';

import { LOCAL_STORAGE_KEYS } from '../../../config/constants/contants';

import placeholderImage from '../../../images/placeholder.jpg';

import commons from '../../../shared/styles/common.module.scss';
import styles from './EventItem.module.scss';

type TProps = {
  event: TEvent;
};

const EventItem = ({ event }: TProps) => {
  const { setLocalStorageItem } = useLocalStorage();

  const savePostIdInLocalStorage = (id: number, link: string) => {
    setLocalStorageItem(LOCAL_STORAGE_KEYS.EVENT_ID, String(id));
    window.location.href = link;
  };

  return (
    <div className={classNames(styles.eventContainer, commons.container)}>
      <div className={styles.eventContent}>
        <a
          key={event.id}
          href={event.link}
          onClick={(e) => {
            e.preventDefault();
            savePostIdInLocalStorage(event.id, event.link);
          }}
          className={styles.eventWrapper}>
          <div className={styles.eventText}>
            <h4 className={styles.eventTitle}>{event.title}</h4>
            {Object.values(event.dates).some((item) => item) && (
              <p className={styles.eventDate}>
                <span>{event.dates.start_date}</span>
                {event.dates.end_date && (
                  <>
                    <span>-</span>
                    {event.dates.end_date}
                  </>
                )}
              </p>
            )}
            <p className={styles.eventExcerpt}>{event.excerpt}</p>
          </div>
          <img
            src={event.featured_image || placeholderImage}
            alt={event.title}
            className={styles.eventImage}
          />
        </a>
      </div>
    </div>
  );
};

export default EventItem;
