import classNames from 'classnames';

import styles from './Button.module.scss';

type TProps = {
  className?: string;
  label: string;
  testId?: string;
  variant: 'default' | 'primary' | 'plain' | 'formal';
  onClick: () => void;
};

const Button = ({ className, label, variant, testId, onClick }: TProps) => {
  return (
    <button
      type='button'
      className={classNames(
        styles.button,
        {
          [styles.buttonPrimary]: variant === 'primary',
          [styles.buttonDefault]: variant === 'default',
          [styles.buttonPlain]: variant === 'plain',
          [styles.buttonFormal]: variant === 'formal',
        },
        className,
      )}
      onClick={onClick}
      data-testid={testId}>
      {label}
    </button>
  );
};

export default Button;
