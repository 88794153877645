import { useTranslation } from 'react-i18next';

import Section from '../../../../components/Section/Section';

type TProps = {
  image: string;
};

const DocumentaryAttestation = ({ image }: TProps) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('fortress-history.content.documentary-attestation.title')}
      lineColor='green'
      variant='dark'
      content={
        <>
          <p>
            {t('fortress-history.content.documentary-attestation.line-one')}
          </p>
          <p>
            {t('fortress-history.content.documentary-attestation.line-two')}
          </p>
        </>
      }
      image={image}
      imageCaption={undefined}
    />
  );
};

export default DocumentaryAttestation;
