import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useGeneralDataContext } from '../../contexts/useGeneralDataContext/useGeneralDataContext';
import useGetNews from '../../hooks/useGetNews/useGetNews';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import NewsItem from './components/NewsItem';
import PageBanner from '../../common/PageBanner/PageBanner';
import Pagination from '../../common/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  LISTING_PAGE_OFFSET,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import { TNews } from '../../types/TGetNewsResponse';

import styles from './News.module.scss';
import commons from '../../shared/styles/common.module.scss';

const News = () => {
  const { t } = useTranslation();
  const imagesURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  useSetPageTitle({
    pageTitle: `${t('pages.news')} - ${t('general.website-name')}`,
  });

  const [page, setPage] = React.useState<number>(1);

  const { language } = useGeneralDataContext();
  const {
    data: newsData,
    isLoading: isLoadingNews,
    refetch,
  } = useGetNews(LISTING_PAGE_OFFSET, language, page, false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  React.useEffect(() => {
    if (newsData) {
      scrollToTop();
    }
  }, [newsData]);

  React.useEffect(() => {
    refetch();
  }, [page]);

  const gridTemplateRows = React.useMemo(() => {
    if (newsData && newsData.posts) {
      if (newsData.posts.length < LISTING_PAGE_OFFSET) {
        return Math.ceil(newsData.posts.length / 2);
      }
    }

    return 5;
  }, [newsData]);

  return (
    <div>
      <PageBanner
        imageSrc={`${imagesURL}${UPLOADS_FOLDER}/cetatea-fagaras-sala-tronului.jpeg`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
      />
      <div className={classNames(styles.newsContainer, commons.container)}>
        <div className={styles.headingWrapper}>
          <h3 className={commons.subSectionHeading}>{t('pages.news')}</h3>
          <hr className={styles.headingLine} />
        </div>
        {isLoadingNews ? (
          <Spinner variant='brown' />
        ) : (
          <div
            className={styles.newsList}
            style={{
              gridTemplateRows: `repeat(${gridTemplateRows}, 430px)`,
            }}>
            {newsData?.posts.map((newsItem: TNews) => (
              <NewsItem key={newsItem.id} newsItem={newsItem} />
            ))}
          </div>
        )}
        <div className={styles.seeMoreWrapper}>
          <Pagination
            colorVariant='brown'
            currentPage={page}
            pageSize={LISTING_PAGE_OFFSET}
            totalCount={newsData?.total_number || 0}
            siblingCount={2}
            onPageChange={(currentPage) => setPage(currentPage)}
          />
        </div>
      </div>
    </div>
  );
};

export default News;
