import classNames from 'classnames';

import useLocalStorage from '../../../hooks/useLocalStorage/useLocalStorage';

import { TExhibition } from '../../../types/TGetExhibitionsResponse';

import { LOCAL_STORAGE_KEYS } from '../../../config/constants/contants';

import placeholderImage from '../../../images/placeholder.jpg';

import commons from '../../../shared/styles/common.module.scss';
import styles from './ExhibitionItem.module.scss';

type TProps = {
  exhibition: TExhibition;
};

const ExhibitionItem = ({ exhibition }: TProps) => {
  const { setLocalStorageItem } = useLocalStorage();

  const savePostIdInLocalStorage = (id: number, link: string) => {
    setLocalStorageItem(LOCAL_STORAGE_KEYS.EXHIBITION_ID, String(id));
    window.location.href = link;
  };

  return (
    <div className={classNames(styles.exhibitionContainer, commons.container)}>
      <div className={styles.exhibitionContent}>
        <a
          key={exhibition.id}
          href={exhibition.link}
          onClick={(e) => {
            e.preventDefault();
            savePostIdInLocalStorage(exhibition.id, exhibition.link);
          }}
          className={styles.exhibitionWrapper}>
          <div className={styles.exhibitionText}>
            <h4 className={styles.exhibitionTitle}>{exhibition.title}</h4>
            {Object.values(exhibition.dates).some((item) => item) && (
              <p className={styles.exhibitionDate}>
                <span>{exhibition.dates.start_date}</span>
                {exhibition.dates.end_date && (
                  <>
                    <span>-</span>
                    {exhibition.dates.end_date}
                  </>
                )}
              </p>
            )}
            <p className={styles.exhibitionExcerpt}>{exhibition.excerpt}</p>
          </div>
          <img
            src={exhibition.featured_image || placeholderImage}
            alt={exhibition.title}
            className={styles.exhibitionImage}
          />
        </a>
      </div>
    </div>
  );
};

export default ExhibitionItem;
