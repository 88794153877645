import classNames from 'classnames';

import usePagination from '../../hooks/usePagination/usePagination';

import Icon from '../../components/Icon/Icon';

import styles from './Pagination.module.scss';
import { getKeyForCondition } from '../../helpers/helpers';

type TProps = {
  colorVariant: 'green' | 'brown' | 'purple';
  currentPage: number;
  pageSize: number;
  siblingCount: number;
  totalCount: number;
  onPageChange: (page: number) => void;
};

const Pagination = ({
  colorVariant,
  currentPage,
  pageSize,
  siblingCount = 1,
  totalCount,
  onPageChange,
}: TProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (!paginationRange) return null;

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  const iconColor = getKeyForCondition({
    'icon-accent': colorVariant === 'green',
    'icon-brown': colorVariant === 'brown',
    'icon-purple': colorVariant === 'purple',
  });

  return (
    <div className={styles.paginationContainer}>
      {currentPage !== 1 && (
        <button
          type='button'
          className={styles.paginationChevron}
          onClick={onPrevious}>
          <Icon name='fa-chevron-left' color={iconColor} />
        </button>
      )}
      {paginationRange.map((pageNumber) => {
        return (
          <button
            key={pageNumber}
            type='button'
            className={classNames(styles.paginationItem, {
              [styles.paginationItemSelected]: pageNumber === currentPage,
              [styles.paginationItemGreen]: colorVariant === 'green',
              [styles.paginationItemBrown]: colorVariant === 'brown',
              [styles.paginationItemPurple]: colorVariant === 'purple',
            })}
            onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </button>
        );
      })}
      {currentPage !== lastPage && (
        <button
          type='button'
          className={styles.paginationChevron}
          onClick={onNext}>
          <Icon name='fa-chevron-right' color={iconColor} />
        </button>
      )}
    </div>
  );
};

export default Pagination;
