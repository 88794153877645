import translationsDE from './de.json';
import translationsEN from './en.json';
import translationsRO from './ro.json';

const translationsResources = {
  de: {
    translation: translationsDE,
  },
  en: {
    translation: translationsEN,
  },
  ro: {
    translation: translationsRO,
  },
};

export default translationsResources;
