import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetAllYearbooks } from '../../types/TGetAllYearbooks';

const useGetAllYearbooks = () => {
  const { data, isLoading, error } = request<TGetAllYearbooks>(
    `${BASE_URL}/api/v1/yearbooks`,
    QueryKeys.Yearbooks,
    true,
  );

  return { data, isLoading, error };
};

export default useGetAllYearbooks;
