import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsResources from './translationsResources';
import { LOCAL_STORAGE_KEYS } from '../constants/contants';

const selectedLanguageInStorage = localStorage.getItem(
  LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE,
);

i18n.use(initReactI18next).init({
  resources: translationsResources,
  lng: selectedLanguageInStorage?.split('-')[0],
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'ro',
  compatibilityJSON: 'v3',
});

export default i18n;
