import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetPublicationResponse } from '../../types/TGetPublicationResponse';

const useGetPublication = (id: number, shouldQuery: boolean) => {
  const { data, isLoading, error, refetch } = request<TGetPublicationResponse>(
    `${BASE_URL}/api/v1/publication/${id}`,
    QueryKeys.PublicationItem,
    shouldQuery,
  );

  return { data, isLoading, error, refetch };
};

export default useGetPublication;
