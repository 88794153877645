type LocalStorageService = {
  getLocalStorageItem: (key: string) => string | null;
  setLocalStorageItem: (key: string, value: string) => void;
  removeLocalStorageItem: (key: string) => void;
};

const useLocalStorage = (): LocalStorageService => {
  const getLocalStorageItem = (key: string) => {
    return window.localStorage.getItem(key);
  };

  const setLocalStorageItem = (key: string, value: string) => {
    window.localStorage.setItem(key, value);
  };

  const removeLocalStorageItem = (key: string) => {
    return window.localStorage.removeItem(key);
  };

  return {
    getLocalStorageItem,
    setLocalStorageItem,
    removeLocalStorageItem,
  };
};

export default useLocalStorage;
