import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import styles from './VirtualTour.module.scss';
import commons from '../../shared/styles/common.module.scss';

const VirtualTour = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.virtual-tour')} - ${t('general.website-name')}`,
  });

  return (
    <div>
      <div className={classNames(styles.content, commons.container)}>
        <h1 className={classNames(styles.pageTitle, commons.subSectionHeading)}>
          {t('pages.virtual-tour')}
        </h1>
        <div>
          <div className={styles.institutionsList}>
            <span className={styles.institutionsListItem}>
              <a
                href='https://european-union.europa.eu/index_ro'
                target='_blank'
                rel='noreferrer noopener'>
                <img
                  src='https://cetateafagarasului.com/wp-content/themes/mesmerize-child/images/institutions/european_union_logo.png'
                  alt='European Union Logo'
                />
              </a>
            </span>
            <span className={styles.institutionsListItem}>
              <a
                href='https://www.gov.ro/'
                target='_blank'
                rel='noreferrer noopener'>
                <img
                  src='https://cetateafagarasului.com/wp-content/themes/mesmerize-child/images/institutions/romanian_government_logo.png'
                  alt='Romanian Government Logo'
                />
              </a>
            </span>
            <span className={styles.institutionsListItem}>
              <a
                href='https://regio-adrcentru.ro/'
                target='_blank'
                rel='noreferrer noopener'>
                <img
                  src='https://cetateafagarasului.com/wp-content/themes/mesmerize-child/images/institutions/regio_logo.png'
                  alt='Regio Logo'
                />
              </a>
            </span>
            <span className={styles.institutionsListItem}>
              <a
                href='https://mfe.gov.ro/'
                target='_blank'
                rel='noreferrer noopener'>
                <img
                  src='https://cetateafagarasului.com/wp-content/themes/mesmerize-child/images/institutions/structural_instruments_logo.png'
                  alt='Structural Instruments Logo'
                />
              </a>
            </span>
          </div>
          <div className={styles.institutionsContent}>
            <h1 className={styles.projectTitle}>
              {t('virtual-tour.project-title')}
            </h1>
            <p className={styles.uatWrapper}>
              <a
                href='https://primaria-fagaras.ro/'
                rel='noreferrer noopener'
                target='_blank'>
                {t('virtual-tour.uat')}
              </a>
            </p>
            <a
              href='https://arenacad.websharecloud.com/?v=pv&amp;t=p:default,c:panoramaview,h:f,m:t,pr:t&amp;pv=pv1&amp;pv1=vt:3d,u:351927f1-f471-45c2-be7b-b409df25bb89,cf:89.00,dh:f,st:f,cx:-58.16270,cy:123.80548,cz:486.32675,p:1.02085,t:-0.10702&amp;p=p:digitizare-cetatea-fagarasului--346efba9,share:eb377d4c-002b-4c0d-8a79-10327e1ae093'
              target='_blank'
              rel='noopener noreferrer'>
              {t('virtual-tour.access-tour')}
            </a>
            <hr className={styles.horizontalLine} />
            <div className={styles.secondSection}>
              <p className={styles.noMarginParagraph}>
                {t('virtual-tour.content.line-one')}
              </p>
              <p className={styles.noMarginParagraph}>
                <Trans
                  i18nKey='virtual-tour.content.line-two'
                  components={{
                    a: (
                      <a
                        href='https://www.fonduri-ue.ro/'
                        target='_blank'
                        rel='noopener noreferrer'>
                        www.fonduri-ue.ro
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
            <hr className={styles.horizontalLine} />
            <p className={styles.virtualTourTitle}>
              {t('virtual-tour.content.motto-line-one')}
            </p>
            <p className={styles.virtualTourTitle}>
              {t('virtual-tour.content.motto-line-two')}
            </p>
            <p className={styles.virtualTourTitle}>
              {t('virtual-tour.content.motto-line-three')}
            </p>
            <p className={styles.virtualTourSubtitle}>
              {t('virtual-tour.content.disclaimer')}
            </p>
          </div>
          <div className={styles.institutionsRegioBand}>
            <img
              src='https://cetateafagarasului.com/wp-content/themes/mesmerize-child/images/institutions/regio_band.png'
              alt='Regio band'
            />
          </div>
          <div className={styles.institutionsHyperLinks}>
            <a
              href='https://www.inforegio.ro/'
              rel='noreferrer noopener'
              target='_blank'>
              www.inforegio.ro
            </a>
            <a
              href='https://www.facebook.com/inforegio.ro'
              rel='noreferrer noopener'
              target='_blank'>
              facebook.com/inforegio.ro
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualTour;
