import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import classNames from 'classnames';

import { useGeneralDataContext } from '../../contexts/useGeneralDataContext/useGeneralDataContext';
import useGetMonthlyExhibit from '../../hooks/useGetMonthlyExhibit/useGetMonthlyExhibit';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import Icon from '../../components/Icon/Icon';
import PageBanner from '../../common/PageBanner/PageBanner';
import Spinner from '../../components/Spinner/Spinner';

import styles from './MonthlyExhibitPage.module.scss';
import commons from '../../shared/styles/common.module.scss';

const MonthlyExhibitPage = () => {
  const { t } = useTranslation();
  const { language } = useGeneralDataContext();

  const { data } = useGetMonthlyExhibit(language, false);

  useSetPageTitle({
    pageTitle: `${data?.title} - ${t('general.website-name')}`,
  });

  return !data ? (
    <Spinner variant='green' />
  ) : (
    <div>
      <PageBanner
        imageSrc={data.featured_image}
        imageText={data.title}
        title={data.title}
        subtitle={data.period}
      />
      <div className={classNames(styles.exhibitContainer, commons.container)}>
        <div>
          <h1
            className={classNames(
              styles.exhibitTitle,
              commons.subSectionHeading,
            )}>
            {data.title}
          </h1>
          <div className={styles.exhibitContent}>{parse(data.content)}</div>
        </div>
        <div className={styles.exhibitDetails}>
          <p className={styles.exhibitDetailsItem}>
            <span className={styles.exhibitDetailsItemText}>
              <Icon name='fa-regular fa-file' color='icon-accent' />
              <span>{t('general.category')}</span>
            </span>
            <span>{data.category}</span>
          </p>
          <p className={styles.exhibitDetailsItem}>
            <span className={styles.exhibitDetailsItemText}>
              <Icon name='fa-calendar' color='icon-accent' />
              <span>{t('general.dated')}</span>
            </span>
            <span>{data.exhibit_date}</span>
          </p>
          <p className={styles.exhibitDetailsItem}>
            <span className={styles.exhibitDetailsItemText}>
              <Icon name='fa-layer-group' color='icon-accent' />
              <span>{t('general.period')}</span>
            </span>
            <span>{data.period}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MonthlyExhibitPage;
