import classNames from 'classnames';

import styles from './BurgerMenu.module.scss';

type TProps = {
  isBurgerMenuToggled: boolean;
  onBurgerMenuToggle: () => void;
};

const BurgerMenu = ({ isBurgerMenuToggled, onBurgerMenuToggle }: TProps) => {
  return (
    <button
      type='button'
      onClick={onBurgerMenuToggle}
      className={classNames(styles.burgerMenu, {
        [styles.burgerMenuToggled]: isBurgerMenuToggled,
      })}
      data-testid='mobile-burger-menu'>
      {[...Array(3)].map((_, index: number) => (
        <span key={index} className={styles.burgerMenuLine}></span>
      ))}
    </button>
  );
};

export default BurgerMenu;
