import styles from './PageBanner.module.scss';

type TProps = {
  imageSrc: string;
  imageText: string;
  title?: string;
  subtitle?: string;
};

const PageBanner = ({ imageSrc, imageText, title, subtitle }: TProps) => {
  return (
    <div className={styles.bannerWrapper}>
      <img src={imageSrc} alt={imageText} loading='lazy' />
      <div className={styles.bannerOverlay}></div>
      <div className={styles.bannerContent}>
        <h2>{title}</h2>
        <h4>{subtitle}</h4>
      </div>
    </div>
  );
};

export default PageBanner;
