import { useTranslation } from 'react-i18next';

import Icon from '../../../components/Icon/Icon';

import styles from './YearbookEntry.module.scss';

type TProps = {
  detail_link: string;
  label: string;
  summary_link: string;
};

const YearbookEntry = ({ detail_link, label, summary_link }: TProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.yearbookItem}>
      <h3 className={styles.yearbookTitle}>{label}</h3>
      <div>
        <a href={detail_link} rel='noopener noreferrer'>
          <span>{t('yearbook.access-volume')}</span>
          <Icon name='fa-external-link' size={14} color='icon-black' />
        </a>
      </div>
      <div>
        <a href={summary_link} target='_blank' rel='noopener noreferrer'>
          <span>{t('yearbook.access-summary')}</span>
          <Icon name='fa-external-link' size={14} color='icon-black' />
        </a>
      </div>
    </div>
  );
};

export default YearbookEntry;
