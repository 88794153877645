import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import PageBanner from '../../../common/PageBanner/PageBanner';
import Spinner from '../../../components/Spinner/Spinner';

import useGetPage from '../../../hooks/useGetPage/useGetPage';
import { useGeneralDataContext } from '../../../contexts/useGeneralDataContext/useGeneralDataContext';
import useSetPageTitle from '../../../hooks/useSetPageTitle/useSetPageTitle';

import {
  AVAILABLE_LANGUAGES,
  DEFAULT_IMAGE_ALT_TEXT,
} from '../../../config/constants/contants';

import bannerImage from '../../../images/vitralii-new.webp';

import styles from './TermsAndConditions.module.scss';
import commons from '../../../shared/styles/common.module.scss';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const { language } = useGeneralDataContext();

  const pageId = language === AVAILABLE_LANGUAGES.RO ? 9215 : 9746;

  const { data, isLoading } = useGetPage(pageId);

  useSetPageTitle({
    pageTitle: `${t('pages.terms-and-conditions')} - ${t(
      'general.website-name',
    )}`,
  });

  return (
    <div>
      <PageBanner
        imageSrc={bannerImage}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('pages.terms-and-conditions')}
      />
      <div className={classNames(styles.content, commons.container)}>
        {isLoading ? (
          <Spinner variant='green' />
        ) : (
          data && (
            <div className={styles.contentValue}>{parse(data.content)}</div>
          )
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
