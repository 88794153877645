import styles from './FooterBlock.module.scss';

type TProps = {
  children: React.ReactNode;
  icon: React.ReactNode | null;
  testId?: string;
  title: string;
};

const FooterBlock = ({ icon, title, children, testId }: TProps) => {
  return (
    <section data-testid={testId} className={styles.itemModule}>
      <div className={styles.titleModule}>
        {icon}
        <h5 className={styles.title}>{title}</h5>
      </div>
      <div className={styles.description}>{children}</div>
    </section>
  );
};

export default FooterBlock;
