import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetEventResponse } from '../../types/TGetEventResponse';

const useGetEvent = (id: number, shouldQuery: boolean) => {
  const { data, isLoading, error, refetch } = request<TGetEventResponse>(
    `${BASE_URL}/api/v1/event/${id}`,
    QueryKeys.EventsItem,
    shouldQuery,
  );

  return { data, isLoading, error, refetch };
};

export default useGetEvent;
