import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';
import { TGetRecommendedArticleResponse } from '../../types/TGetRecommendedArticleResponse';

const useGetRecommendedArticle = (id: number, shouldQuery: boolean) => {
  const { data, isLoading, error, refetch } =
    request<TGetRecommendedArticleResponse>(
      `${BASE_URL}/api/v1/recommended-article/${id}`,
      QueryKeys.RecommendedArticle,
      shouldQuery,
    );

  return { data, isLoading, error, refetch };
};

export default useGetRecommendedArticle;
