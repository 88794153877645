import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from '../../Visiting.module.scss';
import commons from '../../../../shared/styles/common.module.scss';

const Access = () => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.pageContentContainer, commons.container)}>
      <div className={styles.leftSideContent}>
        <h1 className={styles.sectionHeading}>{t('visiting.access')}</h1>
        <p>{t('visiting.access-details-line-one')}</p>
        <p>{t('visiting.access-details-line-two')}</p>
      </div>
      <div className={styles.rightSideContent}>
        <div className={styles.contentLine}>
          <p className={styles.contentLinePlain}>
            {t('visiting.access-information-list.line-one')}
          </p>
        </div>
        <div className={styles.contentLine}>
          <p className={styles.contentLinePlain}>
            {t('visiting.access-information-list.line-two')}
          </p>
        </div>
        <div className={styles.contentLine}>
          <p className={styles.contentLinePlain}>
            {t('visiting.access-information-list.line-three')}
          </p>
        </div>
        <div className={styles.contentLine}>
          <p className={styles.contentLinePlain}>
            {t('visiting.access-information-list.line-four')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Access;
