import { useTranslation } from 'react-i18next';

import Section from '../../../../components/Section/Section';

type TProps = {
  image: string;
};

const StrategicImportance = ({ image }: TProps) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('fortress-history.content.strategic-importance.title')}
      lineColor='brown'
      variant='light'
      content={
        <>
          <p>{t('fortress-history.content.strategic-importance.line-one')}</p>
          <p>{t('fortress-history.content.strategic-importance.line-two')}</p>
        </>
      }
      image={image}
      imageCaption={undefined}
    />
  );
};

export default StrategicImportance;
