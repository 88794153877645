import classNames from 'classnames';
import styles from './Spinner.module.scss';

type TProps = {
  testId?: string;
  variant?: 'green' | 'brown' | 'purple';
};

const Spinner = ({ testId, variant }: TProps) => {
  return (
    <div
      className={styles.spinnerModule}
      aria-label='Loading'
      data-testid={testId}>
      <div
        className={classNames(styles.spinnerDot, styles.bounce1, {
          [styles.greenSpinnerDot]: variant === 'green',
          [styles.brownSpinnerDot]: variant === 'brown',
          [styles.purpleSpinnerDot]: variant === 'purple',
        })}></div>
      <div
        className={classNames(styles.spinnerDot, styles.bounce2, {
          [styles.greenSpinnerDot]: variant === 'green',
          [styles.brownSpinnerDot]: variant === 'brown',
          [styles.purpleSpinnerDot]: variant === 'purple',
        })}></div>
      <div
        className={classNames(styles.spinnerDot, styles.bounce3, {
          [styles.greenSpinnerDot]: variant === 'green',
          [styles.brownSpinnerDot]: variant === 'brown',
          [styles.purpleSpinnerDot]: variant === 'purple',
        })}></div>
    </div>
  );
};

export default Spinner;
