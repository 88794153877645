import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetRecommendedArticlesResponse } from '../../types/TGetRecommendedArticlesResponse';
import { TSupportedLanguages } from '../../types/TSupportedLanguages';

const useGetRecommendedArticles = (language: TSupportedLanguages) => {
  const { data, isLoading, error, refetch } =
    request<TGetRecommendedArticlesResponse>(
      `${BASE_URL}/api/v1/recommended-articles?language=${language}`,
      QueryKeys.RecommendedArticles,
      true,
    );

  return { data, isLoading, error, refetch };
};

export default useGetRecommendedArticles;
