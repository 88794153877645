import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetNewsItemResponse } from '../../types/TGetNewsItemResponse';

const useGetNewsItem = (id: number, shouldQuery: boolean) => {
  const { data, isLoading, error, refetch } = request<TGetNewsItemResponse>(
    `${BASE_URL}/api/v1/news/${id}`,
    QueryKeys.NewsItem,
    shouldQuery,
  );

  return { data, isLoading, error, refetch };
};

export default useGetNewsItem;
