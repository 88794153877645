import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from '../../Visiting.module.scss';
import commons from '../../../../shared/styles/common.module.scss';

const Directions = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.pageContentContainer, commons.container)}>
      <div className={styles.leftSideContent}>
        <h1 className={styles.sectionHeading}>{t('visiting.directions')}</h1>
        <p>{t('visiting.directions-details-line-one')}</p>
        <p>{t('visiting.directions-details-line-two')}</p>
      </div>
      <div className={classNames(styles.rightSideContent, styles.noBackground)}>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2779.3018835318367!2d24.971221476183604!3d45.8452572079336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474ca179cf91571d%3A0x2b97cb31cca4b2df!2zQ2V0YXRlYSBGxINnxINyYciZdWx1aQ!5e0!3m2!1sen!2sro!4v1700765435182!5m2!1sen!2sro'
          allowFullScreen={false}
          loading='lazy'
          title='Cetatea Fagarasului'
          referrerPolicy='no-referrer-when-downgrade'></iframe>
      </div>
    </div>
  );
};

export default Directions;
