import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from '../../../../components/Icon/Icon';

import { getWorkingSchedule } from '../../../../helpers/helpers';

import styles from '../../Visiting.module.scss';
import commons from '../../../../shared/styles/common.module.scss';

type TProps = {
  workingSchedule: string;
};

const Schedule = ({ workingSchedule }: TProps) => {
  const { t } = useTranslation();
  const { weekSchedule, weekendSchedule } = getWorkingSchedule(workingSchedule);

  return (
    <div className={classNames(styles.pageContentContainer, commons.container)}>
      <div className={styles.leftSideContent}>
        <h1 className={styles.sectionHeading}>{t('visiting.opening-hours')}</h1>
        <p>{t('visiting.opening-hours-details-line-one')}</p>
        <p>{t('visiting.opening-hours-details-line-two')}</p>
      </div>
      <div className={styles.rightSideContent}>
        <div className={styles.contentLine}>
          <p className={styles.contentLineEmphasized}>{t('schedule.closed')}</p>
          <p className={styles.contentLinePlain}>{t('days.monday')}</p>
        </div>
        <div className={styles.contentLine}>
          <div className={styles.contentLineEmphasized}>{weekSchedule}</div>
          <p className={styles.contentLinePlain}>
            <span>{t('days.tuesday')}</span>
            <span>
              <Icon name='fa-arrow-right' color='icon-black' size={14} />
            </span>
            <span>{t('days.friday')}</span>
          </p>
          <p>{t('visiting.opening-hours-details-line-three')}</p>
        </div>
        <div className={styles.contentLine}>
          <div className={styles.contentLineEmphasized}>{weekendSchedule}</div>
          <p className={styles.contentLinePlain}>
            <span>{t('days.saturday')}</span>
            <span>
              <Icon name='fa-arrow-right' color='icon-black' size={14} />
            </span>
            <span>{t('days.sunday')}</span>
          </p>
          <p>{t('visiting.opening-hours-details-line-three')}</p>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
