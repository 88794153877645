import { lazy } from 'react';
import { useTranslation } from 'react-i18next';

import { useGeneralDataContext } from '../../contexts/useGeneralDataContext/useGeneralDataContext';
import useGetNews from '../../hooks/useGetNews/useGetNews';
import useGetEvents from '../../hooks/useGetEvents/useGetEvents';
import useGetRecommendedArticles from '../../hooks/useGetRecommendedArticles/useGetRecommendedArticles';
import useGetMonthlyExhibit from '../../hooks/useGetMonthlyExhibit/useGetMonthlyExhibit';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import { TMonthlyExhibit } from '../../types/TGetMonthlyExhibitResponse';
import { TRecommendedArticle } from '../../types/TGetRecommendedArticlesResponse';

import commons from '../../shared/styles/common.module.scss';

const NewsAndUpcomingEvents = lazy(
  () => import('./components/NewsAndUpcomingEvents/NewsAndUpcomingEvents'),
);
const Banner = lazy(() => import('./components/Banner/Banner'));
const Recommended = lazy(() => import('./components/Recommended/Recommended'));
const MonthlyExhibit = lazy(
  () => import('./components/MonthlyExhibit/MonthlyExhibit'),
);

const Home = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('general.website-name')}`,
  });

  const { categorizedPosts, language } = useGeneralDataContext();

  const { data: newsData, isLoading: isLoadingNews } = useGetNews(
    5,
    language,
    1,
    true,
  );
  const { data: eventsData, isLoading: isLoadingEvents } = useGetEvents(
    3,
    language,
    1,
    true,
  );
  const { data: recommendedArticles, isLoading: isLoadingRecommendedArticles } =
    useGetRecommendedArticles(language);
  const { data: monthlyExhibit, isLoading: isLoadingMonthlyExhibit } =
    useGetMonthlyExhibit(language, true);

  return (
    <div className={commons.containerFullWidth}>
      <Banner
        workingSchedule={
          categorizedPosts && categorizedPosts.length > 0
            ? categorizedPosts[2].content
            : undefined
        }
      />
      <NewsAndUpcomingEvents
        eventsData={eventsData?.posts}
        isLoadingEvents={isLoadingEvents}
        isLoadingNews={isLoadingNews}
        newsData={newsData?.posts}
      />
      <MonthlyExhibit
        data={monthlyExhibit as TMonthlyExhibit}
        isLoading={isLoadingMonthlyExhibit}
      />
      <Recommended
        data={recommendedArticles as TRecommendedArticle[]}
        isLoading={isLoadingRecommendedArticles}
      />
    </div>
  );
};

export default Home;
