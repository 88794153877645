import classNames from 'classnames';
import { getKeyForCondition } from '../../helpers/helpers';

type TIconColor =
  | 'icon-default'
  | 'icon-accent'
  | 'icon-neutral'
  | 'icon-gray'
  | 'icon-black'
  | 'icon-brown'
  | 'icon-purple';

type TProps = {
  name: string;
  color: TIconColor;
  size?: number;
  testId?: string;
};

const Icon = ({ name, color, size = 12, testId }: TProps) => {
  return (
    <i
      className={classNames('fas', name)}
      style={{
        fontSize: `${size}px`,
        color: getKeyForCondition({
          'var(--icon-default)': color === 'icon-default',
          'var(--icon-accent)': color === 'icon-accent',
          'var(--icon-neutral)': color === 'icon-neutral',
          'var(--icon-gray)': color === 'icon-gray',
          'var(--icon-black)': color === 'icon-black',
          'var(--icon-brown)': color === 'icon-brown',
        }),
      }}
      data-testid={testId}></i>
  );
};

export default Icon;
