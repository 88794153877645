import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import useGetContests from '../../../hooks/useGetContests/useGetContests';
import useSetPageTitle from '../../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../../common/PageBanner/PageBanner';
import Section from '../../../components/Section/Section';
import Spinner from '../../../components/Spinner/Spinner';

import { DEFAULT_IMAGE_ALT_TEXT } from '../../../config/constants/contants';

import { TContest } from '../../../types/TGetContestsResponse';

import bannerImage from '../../../images/vitralii-new.webp';

import styles from './Contests.module.scss';
import commons from '../../../shared/styles/common.module.scss';

const Contests = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetContests();

  useSetPageTitle({
    pageTitle: `${t('pages.contests')} - ${t('general.website-name')}`,
  });

  React.useEffect(() => {
    if (data) {
      const linksSelectors = document
        .getElementById('contests-list')
        ?.querySelectorAll('p a');

      linksSelectors?.forEach((selector) => {
        const iElement = document.createElement('i');
        iElement.className = 'fas fa-external-link';
        iElement.style.fontSize = 'var(--font-size-sm)';
        iElement.style.paddingLeft = 'var(--spacing-4)';
        selector.appendChild(iElement);
      });
    }
  }, [data]);

  return (
    <div>
      <PageBanner
        imageSrc={bannerImage}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('pages.contests')}
      />
      <div
        className={classNames(styles.content, commons.container)}
        id='contests-list'>
        {isLoading ? (
          <Spinner variant='green' />
        ) : (
          data && (
            <div className={styles.contestsList}>
              <Section
                title={t('contests.jobs')}
                variant='light'
                lineColor='green'
                image={undefined}
                imageCaption={undefined}
                content={
                  <>
                    <h2 className={styles.sectionHeading}>
                      {t('contests.available-jobs')}
                    </h2>
                    {data.contests.map((contest: TContest) => (
                      <div className={styles.contestItem}>
                        <h3 className={styles.contestTitle}>{contest.title}</h3>
                        <div>{parse(contest.content)}</div>
                      </div>
                    ))}
                  </>
                }
              />
              <Section
                title={t('contests.promotions')}
                variant='light'
                lineColor='purple'
                image={undefined}
                imageCaption={undefined}
                content={
                  <>
                    {data.promotions.map((promotion: TContest) => (
                      <div className={styles.contestItem}>
                        <h3 className={styles.contestTitle}>
                          {promotion.title}
                        </h3>
                        <div>{parse(promotion.content)}</div>
                      </div>
                    ))}
                  </>
                }
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Contests;
