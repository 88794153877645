import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetExhibitionsResponse } from '../../types/TGetExhibitionsResponse';
import { TSupportedLanguages } from '../../types/TSupportedLanguages';

const useGetExhibitions = (
  offset: number,
  language: TSupportedLanguages,
  page: number,
) => {
  const { data, isLoading, error, refetch } = request<TGetExhibitionsResponse>(
    `${BASE_URL}/api/v1/exhibitions?offset=${offset}&language=${language}&page=${page}`,
    QueryKeys.Exhibitions,
    true,
    page,
  );

  return { data, isLoading, error, refetch };
};

export default useGetExhibitions;
