import React, { createContext, useMemo } from 'react';

import useGetPosts from '../../hooks/useGetPosts/useGetPosts';

import { getLanguage } from '../../helpers/helpers';

import { TPost } from '../../types/TGetPostsResponse';
import { TSupportedLanguages } from '../../types/TSupportedLanguages';

type TGeneralDataContextServiceProps = {
  isLoadingCategorizedPosts: boolean;
  categorizedPosts: TPost[] | undefined;
  language: TSupportedLanguages;
};

const GeneralDataContext = createContext<TGeneralDataContextServiceProps>(
  {} as TGeneralDataContextServiceProps,
);

export const GeneralDataProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const language: TSupportedLanguages = React.useMemo(() => getLanguage(), []);
  const notRomanian = language === 'en' ? '257' : '277';
  const postsCategories = language === 'ro' ? '254' : notRomanian;

  const { data: categorizedPosts, isLoading } = useGetPosts(
    postsCategories,
    language,
  );

  const value = useMemo(
    () => ({
      categorizedPosts: categorizedPosts
        ? (categorizedPosts as TPost[])
        : undefined,
      language,
      isLoadingCategorizedPosts: isLoading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categorizedPosts, isLoading],
  );

  return (
    <GeneralDataContext.Provider value={value}>
      {children}
    </GeneralDataContext.Provider>
  );
};

export const useGeneralDataContext = () => {
  return React.useContext(GeneralDataContext);
};
