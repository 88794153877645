import { Trans, useTranslation } from 'react-i18next';

import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';
import Section from '../../components/Section/Section';

import { DEFAULT_IMAGE_ALT_TEXT } from '../../config/constants/contants';

import bannerImage from '../../images/cetatea-pictura-pop-de-szathmary.jpeg';
import josephineMap from '../../images/fagaras-harta-iosefina.jpeg';
import olahus from '../../images/nicolaus-olahus-1493-1568-umanist.jpeg';

import styles from './ForeignTravellers.module.scss';

const ForeignTravellers = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('foreign-travellers.title')} - ${t(
      'general.website-name',
    )}`,
  });

  return (
    <div>
      <PageBanner
        imageSrc={bannerImage}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('foreign-travellers.title')}
      />
      <div className={styles.pageContainer}>
        <Section
          title='Anton Verancsics, Nicolaus Olahus, Georg Reichersdorffer'
          lineColor='brown'
          variant='light'
          content={
            <>
              <p>
                <Trans
                  i18nKey='foreign-travellers.content.paragraph-one'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='foreign-travellers.content.paragraph-two'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='foreign-travellers.content.paragraph-three'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='foreign-travellers.content.paragraph-four'
                  components={{ i: <i /> }}
                />
              </p>
            </>
          }
          image={olahus}
          imageCaption={t('foreign-travellers.images.olahus')}
        />
        <Section
          title='Giorgio Tomasi'
          lineColor='pink'
          variant='lighter'
          content={
            <p>
              <Trans
                i18nKey='foreign-travellers.content.paragraph-five'
                components={{ i: <i /> }}
              />
            </p>
          }
          image={josephineMap}
          imageCaption={t('foreign-travellers.images.josephine-map')}
        />
        <Section
          title='Conrad Iacob Hiltebrandt, Evlia Celebi'
          lineColor='green'
          variant='dark'
          content={
            <>
              <p>
                <Trans
                  i18nKey='foreign-travellers.content.paragraph-six'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='foreign-travellers.content.paragraph-seven'
                  components={{ i: <i /> }}
                />
              </p>
            </>
          }
          image={undefined}
          imageCaption={undefined}
        />
      </div>
    </div>
  );
};

export default ForeignTravellers;
