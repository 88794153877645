import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetPageResponse } from '../../types/TGetPageResponse';

const useGetPage = (id: number) => {
  const { data, isLoading, error, refetch } = request<TGetPageResponse>(
    `${BASE_URL}/api/v1/page/${id}`,
    QueryKeys.UniquePage,
    true,
  );

  return { data, isLoading, error, refetch };
};

export default useGetPage;
