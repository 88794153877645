import { Trans, useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import classNames from 'classnames';

import { useGeneralDataContext } from '../../contexts/useGeneralDataContext/useGeneralDataContext';

import Icon from '../../components/Icon/Icon';
import FooterBlock from './components/FooterBlock/FooterBlock';
import Skeleton from '../../components/Skeleton/Skeleton';

import { TPost } from '../../types/TGetPostsResponse';
import { AvailableLanguages } from '../../interfaces/IAvailableLanguages';
import { routes } from '../../config/constants/routes';

import {
  DEV_BASE_URL,
  IS_DEV,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import styles from './Footer.module.scss';
import commons from '../../shared/styles/common.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const { categorizedPosts, isLoadingCategorizedPosts, language } =
    useGeneralDataContext();

  const isRomanian = language === 'ro';

  const imagesURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  return (
    <footer className={styles.footerModule}>
      <div className={styles.footerContainer}>
        <div className={styles.footerColumn}>
          {isLoadingCategorizedPosts ? (
            <Skeleton rows={140} repeat={3} />
          ) : (
            categorizedPosts &&
            categorizedPosts.map(({ id, title, content, icon }: TPost) => (
              <FooterBlock
                key={id}
                title={title}
                icon={<Icon name={icon} color='icon-black' size={14} />}>
                <Trans>{parse(content)}</Trans>
              </FooterBlock>
            ))
          )}
        </div>
        <div className={styles.footerColumn}>
          <FooterBlock title={t('footer.headings.about-us')} icon={null}>
            {isLoadingCategorizedPosts ? (
              <Skeleton rows={150} />
            ) : (
              <ul>
                <li>
                  <a
                    className={styles.footerLink}
                    href={
                      routes[`${language}` as keyof AvailableLanguages].contact
                    }
                    target='_self'>
                    {t('footer.links.contact')}
                  </a>
                </li>
                <li>
                  <a
                    className={styles.footerLink}
                    href={
                      routes[`${language}` as keyof AvailableLanguages].events
                    }
                    target='_self'>
                    {t('footer.links.events')}
                  </a>
                </li>
                <li>
                  <a
                    className={styles.footerLink}
                    href={
                      routes[`${language}` as keyof AvailableLanguages]
                        .exhibitions
                    }
                    target='_self'>
                    {t('footer.links.exhibitions')}
                  </a>
                </li>
                <li>
                  <a
                    className={styles.footerLink}
                    href={
                      routes[`${language}` as keyof AvailableLanguages].news
                    }
                    target='_self'>
                    {t('footer.links.news')}
                  </a>
                </li>
                <li>
                  <a
                    className={styles.footerLink}
                    href={
                      routes[`${language}` as keyof AvailableLanguages].visiting
                    }
                    target='_self'>
                    {t('footer.links.visiting')}
                  </a>
                </li>
              </ul>
            )}
          </FooterBlock>
          {isRomanian && (
            <FooterBlock
              title={t('footer.headings.public-interest-information')}
              icon={null}>
              {isLoadingCategorizedPosts ? (
                <Skeleton rows={150} />
              ) : (
                <ul>
                  <li>
                    <a
                      className={styles.footerLink}
                      href={routes.ro.codeOfConduct}
                      target='_self'>
                      {t('footer.links.code-of-conduct')}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerLink}
                      href={routes.ro.contests}
                      target='_self'>
                      {t('footer.links.contests')}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerLink}
                      href={routes.ro.wealthDeclaration}
                      target='_self'>
                      {t('footer.links.wealth-declaration')}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerLink}
                      href={routes.ro.integrityDeclaration}
                      target='_self'>
                      {t('footer.links.integrity-declaration')}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerLink}
                      href={routes.ro.salaryRights}
                      target='_self'>
                      {t('footer.links.salary-rights')}
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.footerLink}
                      href={routes.ro.operatingRegulations}
                      target='_self'>
                      {t('footer.links.operating-regulations')}
                    </a>
                  </li>
                </ul>
              )}
            </FooterBlock>
          )}
        </div>
        <div className={styles.footerColumn}>
          <FooterBlock
            title={t('footer.headings.excellence-cerfiticate')}
            icon={null}>
            <a
              href='https://www.tripadvisor.com/Attraction_Review-g815576-d2324062-Reviews-Fagaras_Fortress-Fagaras_Brasov_County_Central_Romania_Transylvania.html'
              target='_blank'
              rel='noopener noreferrer'
              className={styles.tripAdvisorLink}>
              <img
                src={`${imagesURL}${UPLOADS_FOLDER}/trip-advisor.png`}
                alt='Trip Advisor'
                loading='lazy'
              />
            </a>
          </FooterBlock>
          <FooterBlock title={t('footer.headings.partners')} icon={null}>
            <a
              href='http://www.muzet.ro'
              target='_blank'
              rel='noopener noreferrer'
              className={styles.partnersItem}>
              <img
                className={styles.partnersLogo}
                src={`${imagesURL}${UPLOADS_FOLDER}/muzeele-din-estul-transilvaniei.webp`}
                alt='Muzeele din Estul Transilvaniei'
              />
            </a>
            <a
              href='https://muzeulbucurestiului.ro/'
              target='_blank'
              rel='noopener noreferrer'
              className={styles.partnersItem}>
              <img
                className={styles.partnersLogo}
                src={`${imagesURL}${UPLOADS_FOLDER}/logo-mmb.png`}
                alt='Muzeul Municipiului București'
              />
            </a>
          </FooterBlock>
        </div>
      </div>
      <div className={classNames(commons.container, styles.copyrightModule)}>
        <ul>
          <li>
            <a
              className={styles.footerLink}
              href={
                routes[`${language}` as keyof AvailableLanguages].privacyPolicy
              }
              target='_self'>
              {t('footer.links.privacy-policy')}
            </a>
          </li>
          <li>
            <a
              className={styles.footerLink}
              href={
                routes[`${language}` as keyof AvailableLanguages]
                  .termsAndConditions
              }
              target='_self'>
              {t('footer.links.terms-and-conditions')}
            </a>
          </li>
          <li>
            <a
              className={styles.footerLink}
              href={routes[`${language}` as keyof AvailableLanguages].cookies}
              target='_self'>
              {t('footer.links.cookies')}
            </a>
          </li>
        </ul>
        <span>
          <Trans
            i18nKey='footer.copyright'
            values={{ year: new Date().getFullYear() }}
          />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
