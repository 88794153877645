import { useTranslation } from 'react-i18next';

import { useGeneralDataContext } from '../../contexts/useGeneralDataContext/useGeneralDataContext';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import Access from './components/Access/Access';
import Amenities from './components/Amenities/Amenities';
import Directions from './components/Directions/Directions';
import PageBanner from '../../common/PageBanner/PageBanner';
import Schedule from './components/Schedule/Schedule';
import Tickets from './components/Tickets/Tickets';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import styles from './Visiting.module.scss';

const Visiting = () => {
  const { t } = useTranslation();
  const imagesURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  const { categorizedPosts } = useGeneralDataContext();

  useSetPageTitle({
    pageTitle: `${t('visiting.title')} - ${t('general.website-name')}`,
  });

  return (
    <div>
      <PageBanner
        imageSrc={`${imagesURL}${UPLOADS_FOLDER}/cetatea-iarna.jpg`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('visiting.title')}
        subtitle={t('visiting.subtitle')}
      />
      <div className={styles.pageContent}>
        {categorizedPosts && categorizedPosts.length > 0 && (
          <Schedule workingSchedule={categorizedPosts[2].content} />
        )}
        <Tickets />
        <Directions />
        <Access />
        <Amenities />
      </div>
    </div>
  );
};

export default Visiting;
