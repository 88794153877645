import { format } from 'date-fns';
import { ro, enGB } from 'date-fns/locale';

import { AVAILABLE_LANGUAGES } from '../config/constants/contants';
import { TSupportedLanguages } from '../types/TSupportedLanguages';

export const getKeyForCondition = <T extends Record<string, boolean>>(
  args: T,
): keyof T => {
  const resultObj = Object.entries(args).find(([, value]) => !!value);

  return resultObj ? resultObj[0] : '';
};

export const getLanguage = (): TSupportedLanguages => {
  const langAttr = document
    .getElementsByTagName('html')[0]
    .getAttribute('lang');
  const langAttrValuesLength = langAttr?.split('-').length || 1;

  return (
    langAttrValuesLength > 1 ? langAttr?.split('-')[0] : AVAILABLE_LANGUAGES.RO
  ) as TSupportedLanguages;
};

export const encodeb64String = (str: string) => {
  if (!str) return '';

  return btoa(encodeURIComponent(str));
};

export const dateFormat = (date: string, parseFormat: string) => {
  const language = getLanguage();

  return format(new Date(date), parseFormat, {
    locale: language === 'ro' ? ro : enGB,
  });
};

export const getWorkingSchedule = (workingSchedule: string) => {
  let schedule;
  let lastEntry;
  let weekendSchedule;

  const dayOfTheWeek = new Date().getDay();

  if (dayOfTheWeek === 0 || dayOfTheWeek === 6) {
    const dailySchedule = workingSchedule
      .split('<p>')
      [workingSchedule.split('<p>').length - 1].split('<')[0];
    schedule = dailySchedule.split('(')[0];
    weekendSchedule = dailySchedule.split('(')[0];
    lastEntry = dailySchedule.split('(')[1].split(')')[0];
  } else if (dayOfTheWeek === 1) {
    schedule = 'schedule.closed';
    lastEntry = null;
  } else {
    const dailySchedule = workingSchedule.split('<p>')[3].split('</p>')[0];
    schedule = dailySchedule.split('(')[0];
    lastEntry = dailySchedule.split('(')[1].split(')')[0];
  }

  const closingHour =
    dayOfTheWeek !== 1
      ? Number(schedule.split(':')[1].split(' ')[2])
      : Number('8');

  const closed = !!closingHour && closingHour <= new Date().getHours();

  return {
    closed,
    schedule,
    weekSchedule: workingSchedule.split('<p>')[3].split('(')[0],
    weekendSchedule:
      workingSchedule.split('<p>')[5].split('(')[0] || weekendSchedule,
    lastEntry,
  };
};
