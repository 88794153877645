import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';

import styles from './Modal.module.scss';

type TProps = {
  children: React.ReactNode;
  isOpen: boolean;
  primaryButton:
    | {
        label: string;
      }
    | undefined;
  secondaryButton:
    | {
        label: string;
      }
    | undefined;
  size?: 'medium' | 'large';
  title: string;
  onClose: () => void;
  onSubmit?: () => void;
};

const Modal = ({
  children,
  isOpen,
  size = 'medium',
  title,
  primaryButton,
  secondaryButton,
  onClose,
  onSubmit,
}: TProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.modal, {
        [styles.open]: isOpen,
        [styles.modalLarge]: size === 'large',
      })}>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalTitle}>{title}</h3>
        <button type='button' onClick={onClose}>
          <Icon name='fa-solid fa-xmark' color='icon-black' size={20} />
        </button>
      </div>
      <div
        className={classNames(styles.modalContent, {
          [styles.noFooter]: !primaryButton && !secondaryButton,
        })}>
        {children}
      </div>
      {(primaryButton || secondaryButton) && (
        <div className={styles.modalFooter}>
          <Button
            variant='plain'
            onClick={onClose}
            label={secondaryButton?.label || t('cookies-banner.buttons.close')}
          />
          <Button
            variant='primary'
            onClick={() => onSubmit?.()}
            label={primaryButton?.label || t('cookies-banner.buttons.save')}
          />
        </div>
      )}
    </div>
  );
};

export default Modal;
