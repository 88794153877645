import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetEventsResponse } from '../../types/TGetEventsResponse';

const useGetWealthDeclarations = () => {
  const { data, isLoading, error, refetch } = request<TGetEventsResponse>(
    `${BASE_URL}/api/v1/declarations`,
    QueryKeys.WealthDeclarations,
    true,
  );

  return { data, isLoading, error, refetch };
};

export default useGetWealthDeclarations;
