import { useTranslation } from 'react-i18next';

import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';
import useGetAllYearbooks from '../../hooks/useGetAllYearbooks/useGetAllYearbooks';

import PageBanner from '../../common/PageBanner/PageBanner';
import Section from '../../components/Section/Section';
import Spinner from '../../components/Spinner/Spinner';
import YearbookEntry from './components/YearbookEntry';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  IS_DEV,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import { TYearbook } from '../../types/TGetAllYearbooks';

import bannerImage from '../../images/cetatea-fagaras-vechi.webp';

import styles from './Yearbook.module.scss';

const Yearbook = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetAllYearbooks();

  useSetPageTitle({
    pageTitle: `${t('yearbook.title')} - ${t('general.website-name')}`,
  });

  const BASE_PATH = IS_DEV ? '/cetateafagarasului-v2/' : '/';

  return (
    <div>
      <PageBanner
        imageSrc={bannerImage}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('yearbook.title')}
      />
      <div className={styles.pageContainer}>
        <Section
          title={t('yearbook.volumes')}
          variant='light'
          lineColor='green'
          image={undefined}
          imageCaption={undefined}
          content={
            isLoading ? (
              <Spinner variant='green' />
            ) : (
              data &&
              data.editions
                .sort(
                  (a: TYearbook, b: TYearbook) =>
                    Number(b.year) - Number(a.year),
                )
                .map(({ id, label, link, summary_link }: TYearbook) => (
                  <YearbookEntry
                    key={id}
                    detail_link={`${BASE_PATH}${link}`}
                    label={label}
                    summary_link={`${BASE_PATH}${UPLOADS_FOLDER}${summary_link}`}
                  />
                ))
            )
          }
        />
      </div>
    </div>
  );
};

export default Yearbook;
