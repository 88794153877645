import { Trans, useTranslation } from 'react-i18next';

import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';
import Section from '../../components/Section/Section';

import { DEFAULT_IMAGE_ALT_TEXT } from '../../config/constants/contants';

import bannerImage from '../../images/cetatea-fagaras-vechi.webp';
import bombardImage from '../../images/bombarda-secolele-XVII-XVIII.jpeg';
import cannonImage from '../../images/tun-secolul-al-XVII-lea.jpeg';
import siegeImage from '../../images/asediu-secolul-al-XVII-lea.jpeg';

import styles from './FortressWeapons.module.scss';

const FortressWeapons = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('fortress-weapons.title')} - ${t('general.website-name')}`,
  });

  return (
    <div>
      <PageBanner
        imageSrc={bannerImage}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('fortress-weapons.title')}
      />
      <div className={styles.pageContainer}>
        <Section
          title={t('fortress-weapons.content.section-one-title')}
          lineColor='purple'
          variant='light'
          content={
            <>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-one'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-two'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-three'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-four'
                  components={{ i: <i /> }}
                />
              </p>
            </>
          }
          image={cannonImage}
          imageCaption={t('fortress-weapons.images.cannon')}
        />
        <Section
          title={t('fortress-weapons.content.section-two-title')}
          lineColor='pink'
          variant='dark'
          content={
            <>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-five'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-six'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-seven'
                  components={{ i: <i /> }}
                />
              </p>
            </>
          }
          image={bombardImage}
          imageCaption={t('fortress-weapons.images.bombard')}
        />
        <Section
          title={t('fortress-weapons.content.section-three-title')}
          lineColor='brown'
          variant='lighter'
          content={
            <>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-eight'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-nine'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-weapons.content.paragraph-ten'
                  components={{ i: <i /> }}
                />
              </p>
            </>
          }
          image={siegeImage}
          imageCaption={t('fortress-weapons.images.siege')}
        />
      </div>
    </div>
  );
};

export default FortressWeapons;
