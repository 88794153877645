import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useGeneralDataContext } from '../../contexts/useGeneralDataContext/useGeneralDataContext';
import useGetPublications from '../../hooks/useGetPublications/useGetPublications';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';
import Pagination from '../../common/Pagination/Pagination';
import PublicationItem from './components/PublicationItem';
import Spinner from '../../components/Spinner/Spinner';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  LISTING_PAGE_OFFSET,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import { TPublication } from '../../types/TGetPublicationsResponse';

import styles from './Publications.module.scss';
import commons from '../../shared/styles/common.module.scss';

const Publications = () => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState<number>(1);
  const imagesURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  const { language } = useGeneralDataContext();
  const {
    data: publicationsData,
    isLoading: isLoadingPublications,
    refetch,
  } = useGetPublications(LISTING_PAGE_OFFSET, language, page);

  useSetPageTitle({
    pageTitle: `${t('pages.publications')} - ${t('general.website-name')}`,
  });

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  React.useEffect(() => {
    if (publicationsData) {
      scrollToTop();
    }
  }, [publicationsData]);

  React.useEffect(() => {
    refetch();
  }, [page]);

  const gridTemplateRows = React.useMemo(() => {
    if (publicationsData && publicationsData.posts) {
      if (publicationsData.posts.length < LISTING_PAGE_OFFSET) {
        return Math.ceil(publicationsData.posts.length / 2);
      }
    }

    return 5;
  }, [publicationsData]);

  return (
    <div>
      <PageBanner
        imageSrc={`${imagesURL}${UPLOADS_FOLDER}/cetatea-fagaras-sala-tronului.jpeg`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
      />
      <div
        className={classNames(styles.publicationsContainer, commons.container)}>
        <div className={styles.headingWrapper}>
          <h3 className={commons.subSectionHeading}>
            {t('pages.publications')}
          </h3>
          <hr className={styles.headingLine} />
        </div>
        {isLoadingPublications ? (
          <Spinner variant='brown' />
        ) : (
          <div
            className={styles.publicationsList}
            style={{
              gridTemplateRows: `repeat(${gridTemplateRows}, 430px)`,
            }}>
            {publicationsData?.posts.map((publicationItem: TPublication) => (
              <PublicationItem
                key={publicationItem.id}
                publicationItem={publicationItem}
              />
            ))}
          </div>
        )}
        <div className={styles.seeMoreWrapper}>
          <Pagination
            colorVariant='brown'
            currentPage={page}
            pageSize={LISTING_PAGE_OFFSET}
            totalCount={publicationsData?.total_number || 0}
            siblingCount={2}
            onPageChange={(currentPage) => setPage(currentPage)}
          />
        </div>
      </div>
    </div>
  );
};

export default Publications;
