import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetEventsResponse } from '../../types/TGetEventsResponse';
import { TSupportedLanguages } from '../../types/TSupportedLanguages';

const useGetEvents = (
  offset: number,
  language: TSupportedLanguages,
  page: number,
  isHome: boolean,
) => {
  const { data, isLoading, error, refetch } = request<TGetEventsResponse>(
    `${BASE_URL}/api/v1/events?offset=${offset}&language=${language}&page=${page}&isHome=${isHome}`,
    QueryKeys.Events,
    true,
    page,
  );

  return { data, isLoading, error, refetch };
};

export default useGetEvents;
