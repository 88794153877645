import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

import { routes } from '../../config/constants/routes';
import { getLanguage } from '../../helpers/helpers';

import styles from './CookieConsent.module.scss';

type TProps = {
  onOpenSettings: () => void;
};

const CookieConsentComponent = ({ onOpenSettings }: TProps) => {
  const { t } = useTranslation();

  return (
    <CookieConsent
      location='bottom'
      buttonText={t('cookies-banner.buttons.accept')}
      cookieName='cetateaFagarasuluiCookies'
      expires={150}
      buttonClasses={styles.acceptButton}
      containerClasses={styles.containerClass}>
      <div className={styles.consentContent}>
        <p>
          {t('cookies-banner.description')}
          <a
            className={styles.redirect}
            href={routes[getLanguage()].privacyPolicy}
            rel='noreferrer'
            target='_blank'>
            {t('cookies-banner.buttons.read-more')}
          </a>
        </p>
        <button
          className={styles.settingsButton}
          type='button'
          onClick={onOpenSettings}>
          {t('cookies-banner.buttons.settings')}
        </button>
      </div>
    </CookieConsent>
  );
};

export default CookieConsentComponent;
