import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Modal from '../../../common/Modal/Modal';
import Button from '../../../components/Button/Button';

import styles from './YearbookByYearEntry.module.scss';

type TProps = {
  author: string;
  file_url: string;
  resume: string | null;
  resume_title: string | null;
  title: string;
};

const YearbookByYearEntry = ({
  author,
  file_url,
  resume,
  resume_title,
  title,
}: TProps) => {
  const { t } = useTranslation();

  const overlayTag = document.getElementsByClassName('overlay')[0];
  const bodyTag = document.getElementsByTagName('body')[0];

  const [isResumeOpen, setIsResumeOpen] = React.useState<boolean>(false);

  const handleOpenResume = () => {
    setIsResumeOpen(true);
    overlayTag.classList.add('overlayActive');
    bodyTag.style.overflow = 'hidden';
  };

  const handleCloseResume = () => {
    setIsResumeOpen(false);
    overlayTag.classList.remove('overlayActive');
    bodyTag.style.overflow = 'visible';
  };

  return (
    <div className={styles.yearbookItem}>
      <h5 className={styles.yearbookDetails}>
        <span className={styles.yearbookTitle}>{title}</span>
        <span className={styles.yearbookAuthor}>{author}</span>
      </h5>
      <div className={styles.yearbookButtons}>
        <Button
          label={t('yearbook.access-pdf')}
          onClick={() => window.open(file_url, 'blank')}
          variant='formal'
        />
        {resume && (
          <Button
            label={t('yearbook.access-resume')}
            onClick={handleOpenResume}
            variant='formal'
          />
        )}
      </div>
      {resume && (
        <Modal
          isOpen={isResumeOpen}
          size='large'
          title={resume_title || title}
          primaryButton={undefined}
          secondaryButton={undefined}
          onClose={handleCloseResume}>
          {parse(resume)}
        </Modal>
      )}
    </div>
  );
};

export default YearbookByYearEntry;
