import React from 'react';
import classNames from 'classnames';

import useWindowSize from '../../hooks/useWindowSize/useWindowSize';

import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

import { TMenu } from '../../types/TMenu';

import styles from './Menu.module.scss';

type TProps = {
  items: TMenu;
  isBurgerMenuToggled: boolean;
};

const Menu = ({ items, isBurgerMenuToggled }: TProps) => {
  const windowSize = useWindowSize();
  const [expandedNestedMenu, setExpandedNestedMenu] =
    React.useState<string>('');

  const handleMobileClick = React.useCallback((e: React.BaseSyntheticEvent) => {
    const selectedParentMenuId = e.target.getAttribute('data-menu-id');
    setExpandedNestedMenu((prev) => {
      return prev === selectedParentMenuId ? '' : selectedParentMenuId;
    });
  }, []);

  const getChildrenItemsForParent = React.useCallback(
    (parentId: number) => {
      return items.filter((item) => Number(item.menu_item_parent) === parentId);
    },
    [items],
  );

  React.useEffect(() => {
    if (!isBurgerMenuToggled) {
      setExpandedNestedMenu('');
    }
  }, [isBurgerMenuToggled]);

  return (
    <nav
      className={classNames(styles.navigationMenuWrapper, {
        [styles.menuOnMobile]: isBurgerMenuToggled,
      })}
      data-testid='primary-nav-wrap'>
      <ul className={styles.navigationMenu}>
        {items.map(({ ID, url, target, title, menu_item_parent }) => {
          const currentParentChildren = getChildrenItemsForParent(ID);
          const isActive = window.location.href === url;
          const commonProps = {
            key: ID,
            rel: 'noopener noreferrer',
            className: styles.menuItemAnchor,
          };

          return (
            menu_item_parent === '0' && (
              <li
                key={ID}
                className={classNames(styles.menuItem, {
                  [styles.menuItemWithChildren]:
                    currentParentChildren.length > 0,
                  [styles.menuItemActive]: isActive,
                })}
                data-testid={`parent-menu-${ID}`}>
                {currentParentChildren.length > 0 ? (
                  <span
                    {...commonProps}
                    data-testid={`main-menu-anchor-${ID}`}
                    data-menu-id={ID}
                    onClick={(e) => {
                      if (windowSize.width < 1024) {
                        e.preventDefault();
                        handleMobileClick(e);
                      }
                    }}>
                    {title}
                  </span>
                ) : (
                  <a
                    {...commonProps}
                    href={url}
                    target={target || '_self'}
                    data-testid={`main-menu-anchor-${ID}`}>
                    {title}
                  </a>
                )}
                <ul
                  key={`submenu-for-${ID}`}
                  className={classNames(styles.nestedMenu, {
                    [styles.nestedMenuExpanded]:
                      expandedNestedMenu === String(ID),
                  })}
                  data-testid={`submenu-for-${ID}`}>
                  {menu_item_parent === '0' &&
                    currentParentChildren.map((item) => (
                      <li key={item.ID} className={styles.menuItem}>
                        <a
                          {...commonProps}
                          key={item.ID}
                          href={item.url}
                          target={item.target || '_self'}
                          data-testid={`main-menu-nested-anchor-${item.menu_item_parent}`}>
                          {item.title}
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
            )
          );
        })}
      </ul>
      <LanguageSwitcher />
    </nav>
  );
};

export default Menu;
