import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import useGetPage from '../../../hooks/useGetPage/useGetPage';
import useSetPageTitle from '../../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../../common/PageBanner/PageBanner';
import Spinner from '../../../components/Spinner/Spinner';

import { DEFAULT_IMAGE_ALT_TEXT } from '../../../config/constants/contants';

import bannerImage from '../../../images/vitralii.webp';

import styles from './SalaryRights.module.scss';
import commons from '../../../shared/styles/common.module.scss';

const SalaryRights = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetPage(8788);

  useSetPageTitle({
    pageTitle: `${t('pages.salary-rights')} - ${t('general.website-name')}`,
  });

  React.useEffect(() => {
    if (data) {
      const linksSelectors = document
        .getElementById('salary-rights')
        ?.querySelectorAll('li');

      linksSelectors?.forEach((selector) => {
        const iElement = document.createElement('i');
        iElement.className = 'fas fa-external-link';
        iElement.style.fontSize = 'var(--font-size-sm)';
        iElement.style.paddingLeft = 'var(--spacing-4)';
        selector.appendChild(iElement);
      });
    }
  }, [data]);

  return (
    <div>
      <PageBanner
        imageSrc={bannerImage}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('pages.salary-rights')}
      />
      <div
        className={classNames(styles.content, commons.container)}
        id='salary-rights'>
        {isLoading ? (
          <Spinner variant='green' />
        ) : (
          data && (
            <div className={styles.contentValue}>{parse(data.content)}</div>
          )
        )}
      </div>
    </div>
  );
};

export default SalaryRights;
