import classNames from 'classnames';

import useLocalStorage from '../../../hooks/useLocalStorage/useLocalStorage';

import { dateFormat } from '../../../helpers/helpers';

import { LOCAL_STORAGE_KEYS } from '../../../config/constants/contants';

import { TNews } from '../../../types/TGetNewsResponse';

import placeholderImage from '../../../images/placeholder.jpg';

import commons from '../../../shared/styles/common.module.scss';
import styles from './NewsItem.module.scss';

type TProps = {
  newsItem: TNews;
};

const NewsItem = ({ newsItem }: TProps) => {
  const { setLocalStorageItem } = useLocalStorage();

  const savePostIdInLocalStorage = (id: number, link: string) => {
    setLocalStorageItem(LOCAL_STORAGE_KEYS.NEWS_ID, String(id));
    window.location.href = link;
  };

  return (
    <div className={classNames(styles.newsItemContainer, commons.container)}>
      <div className={styles.newsItemContent}>
        <a
          key={newsItem.id}
          href={newsItem.link}
          onClick={(e) => {
            e.preventDefault();
            savePostIdInLocalStorage(newsItem.id, newsItem.link);
          }}
          className={styles.newsItemWrapper}>
          <div className={styles.newsItemText}>
            <h4 className={styles.newsItemTitle}>{newsItem.title}</h4>
            <p className={styles.newsItemDate}>
              <span>{dateFormat(newsItem.created_at, 'EEEE,	d MMMM yyyy')}</span>
            </p>
            <p className={styles.newsItemExcerpt}>{newsItem.excerpt}</p>
          </div>
          <img
            src={newsItem.featured_image || placeholderImage}
            alt={newsItem.title}
            className={styles.newsItemImage}
          />
        </a>
      </div>
    </div>
  );
};

export default NewsItem;
