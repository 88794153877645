import useLocalStorage from '../../hooks/useLocalStorage/useLocalStorage';

import { LOCAL_STORAGE_KEYS } from '../../config/constants/contants';

import roFlag from '../../images/ro-flag.png';
import ukFlag from '../../images/uk-flag.png';
import deFlag from '../../images/de-flag.png';

import styles from './LanguageSwitcher.module.scss';

const LanguageSwitcher = () => {
  const { setLocalStorageItem } = useLocalStorage();

  const handleSetLocale = (locale: 'en' | 'de' | 'ro') => {
    setLocalStorageItem(
      LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE,
      // eslint-disable-next-line no-nested-ternary
      locale === 'en' ? 'en-GB' : locale === 'de' ? 'de-DE' : 'ro-RO',
    );

    window.location.href = `${window.location.origin}/`;
  };

  return (
    <div className={styles.languageSwitcher}>
      <button
        type='button'
        className={styles.languageFlag}
        onClick={() => handleSetLocale('ro')}>
        <img src={roFlag} alt='RO' loading='lazy' />
      </button>
      <button
        type='button'
        className={styles.languageFlag}
        onClick={() => handleSetLocale('en')}>
        <img src={ukFlag} alt='UK' loading='lazy' />
      </button>
      <button
        type='button'
        className={styles.languageFlag}
        onClick={() => handleSetLocale('de')}>
        <img src={deFlag} alt='DE' loading='lazy' />
      </button>
    </div>
  );
};

export default LanguageSwitcher;
