import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import useGetNewsItem from '../../hooks/useGetNewsItem/useGetNewsItem';
import useLocalStorage from '../../hooks/useLocalStorage/useLocalStorage';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import Icon from '../../components/Icon/Icon';
import PageBanner from '../../common/PageBanner/PageBanner';
import Spinner from '../../components/Spinner/Spinner';

import { LOCAL_STORAGE_KEYS } from '../../config/constants/contants';

import styles from './NewsPage.module.scss';
import commons from '../../shared/styles/common.module.scss';

const NewsPage = () => {
  const { t } = useTranslation();
  const { getLocalStorageItem } = useLocalStorage();

  const postId = getLocalStorageItem(LOCAL_STORAGE_KEYS.NEWS_ID);

  const { data, refetch } = useGetNewsItem(Number(postId), false);

  useSetPageTitle({
    pageTitle: `${data?.title} - ${t('general.website-name')}`,
  });

  React.useEffect(() => {
    if (postId) {
      refetch();
    }
  }, [postId]);

  return !data ? (
    <Spinner variant='green' />
  ) : (
    <div>
      <PageBanner
        title={data.title}
        imageSrc={data.featured_image}
        imageText={data.title}
      />
      <div className={classNames(styles.newsItemContainer, commons.container)}>
        <div>
          <h1
            className={classNames(
              styles.newsItemTitle,
              commons.subSectionHeading,
            )}>
            {data.title}
          </h1>
          <div className={styles.newsItemContent}>{parse(data.content)}</div>
          <img
            src={data.featured_image}
            alt={data.title}
            className={styles.newsItemContentImage}
          />
        </div>
        {Object.values(data.dates).some((key) => !!key) && (
          <div className={styles.newsItemDetails}>
            <p className={styles.newsItemDetailsItem}>
              <span className={styles.newsItemDetailsItemText}>
                <Icon name='fa-calendar' color='icon-accent' />
                <span>{t('general.date')}:</span>
              </span>
              <span className={styles.newsItemDetailsItemContent}>
                {data.dates.start_date && <span>{data.dates.start_date}</span>}
                {data.dates.end_date && (
                  <>
                    <span
                      className={styles.newsItemDetailsItemContentSeparator}>
                      -
                    </span>
                    <span>{data.dates.end_date}</span>
                  </>
                )}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsPage;
