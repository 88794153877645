import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useGeneralDataContext } from '../../contexts/useGeneralDataContext/useGeneralDataContext';
import useGetEvents from '../../hooks/useGetEvents/useGetEvents';
import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import EventItem from './components/EventItem';
import PageBanner from '../../common/PageBanner/PageBanner';
import Pagination from '../../common/Pagination/Pagination';
import Spinner from '../../components/Spinner/Spinner';

import {
  DEFAULT_IMAGE_ALT_TEXT,
  DEV_BASE_URL,
  IS_DEV,
  LISTING_PAGE_OFFSET,
  PROD_BASE_URL,
  UPLOADS_FOLDER,
} from '../../config/constants/contants';

import { TEvent } from '../../types/TGetEventsResponse';

import styles from './Events.module.scss';
import commons from '../../shared/styles/common.module.scss';

const Events = () => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState<number>(1);

  const { language } = useGeneralDataContext();

  const imagesURL = IS_DEV ? DEV_BASE_URL : PROD_BASE_URL;

  useSetPageTitle({
    pageTitle: `${t('pages.events')} - ${t('general.website-name')}`,
  });

  const {
    data: eventsData,
    isLoading: isLoadingEvents,
    refetch,
  } = useGetEvents(LISTING_PAGE_OFFSET, language, page, false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  React.useEffect(() => {
    if (eventsData) {
      scrollToTop();
    }
  }, [eventsData]);

  React.useEffect(() => {
    refetch();
  }, [page]);

  const gridTemplateRows = React.useMemo(() => {
    if (eventsData && eventsData.posts) {
      if (eventsData.posts.length < LISTING_PAGE_OFFSET) {
        return Math.ceil(eventsData.posts.length / 2);
      }
    }

    return 5;
  }, [eventsData]);

  return (
    <div>
      <PageBanner
        imageSrc={`${imagesURL}${UPLOADS_FOLDER}/cetatea-fagaras-sala-tronului.jpeg`}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
      />
      <div className={classNames(styles.eventsContainer, commons.container)}>
        <div className={styles.headingWrapper}>
          <h3 className={commons.subSectionHeading}>{t('pages.events')}</h3>
          <hr className={styles.headingLine} />
        </div>
        {isLoadingEvents ? (
          <Spinner variant='brown' />
        ) : (
          <div
            className={styles.eventsList}
            style={{
              gridTemplateRows: `repeat(${gridTemplateRows}, 430px)`,
            }}>
            {eventsData?.posts.map((event: TEvent) => (
              <EventItem key={event.id} event={event} />
            ))}
          </div>
        )}
        <div className={styles.seeMoreWrapper}>
          <Pagination
            colorVariant='brown'
            currentPage={page}
            pageSize={LISTING_PAGE_OFFSET}
            totalCount={eventsData?.total_number || 0}
            siblingCount={2}
            onPageChange={(currentPage) => setPage(currentPage)}
          />
        </div>
      </div>
    </div>
  );
};

export default Events;
