import classNames from 'classnames';

import styles from './Section.module.scss';
import commons from '../../shared/styles/common.module.scss';

type TProps = {
  content: React.ReactNode | string;
  image: string | undefined;
  imageCaption: string | undefined;
  lineColor: 'brown' | 'green' | 'pink' | 'purple';
  title: string | undefined;
  variant: 'light' | 'lighter' | 'dark';
};

const Section = ({
  content,
  image,
  imageCaption,
  lineColor,
  title,
  variant,
}: TProps) => {
  return (
    <div
      className={classNames(styles.section, {
        [styles.lightSection]: variant === 'light',
        [styles.lighterSection]: variant === 'lighter',
        [styles.darkSection]: variant === 'dark',
      })}>
      <div className={styles.container}>
        {title && (
          <div className={styles.headingWrapper}>
            <h3 className={commons.subSectionHeading}>{title}</h3>
            <hr
              className={classNames(styles.headingLine, {
                [styles.brownHorizontalLine]: lineColor === 'brown',
                [styles.greenHorizontalLine]: lineColor === 'green',
                [styles.pinkHorizontalLine]: lineColor === 'pink',
                [styles.purpleHorizontalLine]: lineColor === 'purple',
              })}
            />
          </div>
        )}
        <div
          className={classNames(styles.contentWrapper, {
            [styles.contentWithImage]: !!image,
          })}>
          <div className={styles.content}>{content}</div>
          {image && (
            <div className={styles.imageWrapper}>
              <img src={image} alt={title} loading='lazy' />
              <p className={styles.imageCaption}>{imageCaption}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Section;
