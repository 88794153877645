import request from '../../helpers/request';

import { QueryKeys } from '../../config/constants/react-query';
import { BASE_URL } from '../../config/constants/contants';

import { TGetContestsResponse } from '../../types/TGetContestsResponse';

const useGetContests = () => {
  const { data, isLoading, error } = request<TGetContestsResponse>(
    `${BASE_URL}/api/v1/contests`,
    QueryKeys.Contests,
    true,
  );

  return { data, isLoading, error };
};

export default useGetContests;
