import { Trans, useTranslation } from 'react-i18next';

import Section from '../../../../components/Section/Section';

import styles from '../../FortressHistory.module.scss';

const ArchitecturalEvolution = () => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('fortress-history.content.architectural-evolution.title')}
      lineColor='purple'
      variant='lighter'
      content={
        <>
          <h4>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-one.title',
            )}
          </h4>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-one.line-one',
            )}
          </p>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-one.line-two',
            )}
          </p>
          <hr className={styles.intersectionLine} />
          <h4>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-two.title',
            )}
          </h4>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-two.line-one',
            )}
          </p>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-two.line-two',
            )}
          </p>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-two.line-three',
            )}
          </p>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-two.line-four',
            )}
          </p>
          <hr className={styles.intersectionLine} />
          <h4>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-three.title',
            )}
          </h4>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-three.line-one',
            )}
          </p>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-three.line-two',
            )}
          </p>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-three.line-three',
            )}
          </p>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-three.line-four',
            )}
          </p>
          <hr className={styles.intersectionLine} />
          <h4>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-four.title',
            )}
          </h4>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-four.line-one',
            )}
          </p>
          <hr className={styles.intersectionLine} />
          <h4>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-five.title',
            )}
          </h4>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-five.line-one',
            )}
          </p>
          <p>
            {t(
              'fortress-history.content.architectural-evolution.paragraph-five.line-two',
            )}
          </p>
          <p>
            <Trans
              i18nKey='fortress-history.content.architectural-evolution.paragraph-five.line-three'
              components={{ i: <i /> }}
            />
          </p>
        </>
      }
      image={undefined}
      imageCaption={undefined}
    />
  );
};

export default ArchitecturalEvolution;
