import { Trans, useTranslation } from 'react-i18next';

import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import PageBanner from '../../common/PageBanner/PageBanner';
import Section from '../../components/Section/Section';

import { DEFAULT_IMAGE_ALT_TEXT } from '../../config/constants/contants';

import bannerImage from '../../images/cetatea-pictura-pop-de-szathmary.jpeg';

import styles from './FortressMessengers.module.scss';

const FortressMessengers = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('fortress-messengers.title')} - ${t(
      'general.website-name',
    )}`,
  });

  return (
    <div>
      <PageBanner
        imageSrc={bannerImage}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('fortress-messengers.title')}
      />
      <div className={styles.pageContainer}>
        <Section
          title={undefined}
          lineColor='brown'
          variant='light'
          content={
            <>
              <p>
                <Trans
                  i18nKey='fortress-messengers.content.paragraph-one'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-messengers.content.paragraph-two'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-messengers.content.paragraph-three'
                  components={{ i: <i /> }}
                />
              </p>
            </>
          }
          image={undefined}
          imageCaption={undefined}
        />
        <Section
          title={undefined}
          lineColor='purple'
          variant='lighter'
          content={
            <>
              <p>
                <Trans
                  i18nKey='fortress-messengers.content.paragraph-four'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-messengers.content.paragraph-five'
                  components={{ i: <i /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='fortress-messengers.content.paragraph-six'
                  components={{ i: <i /> }}
                />
              </p>
            </>
          }
          image={undefined}
          imageCaption={undefined}
        />
      </div>
    </div>
  );
};

export default FortressMessengers;
