import { useTranslation } from 'react-i18next';

import useSetPageTitle from '../../hooks/useSetPageTitle/useSetPageTitle';

import ArchitecturalEvolution from './components/ArchitecturalEvolution/ArchitecturalEvolution';
import DocumentaryAttestation from './components/DocumentaryAttestation/DocumentaryAttestation';
import StrategicImportance from './components/StrategicImportance/StrategicImportance';
import PageBanner from '../../common/PageBanner/PageBanner';

import { DEFAULT_IMAGE_ALT_TEXT } from '../../config/constants/contants';

import bannerImage from '../../images/cetatea-fagaras-vechi.webp';
import cetateaSzathmary from '../../images/cetatea-pictura-pop-de-szathmary.jpeg';

import styles from './FortressHistory.module.scss';

const FortressHistory = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('fortress-history.title')} - ${t('general.website-name')}`,
  });

  return (
    <div>
      <PageBanner
        imageSrc={bannerImage}
        imageText={DEFAULT_IMAGE_ALT_TEXT}
        title={t('fortress-history.title')}
      />
      <div className={styles.pageContainer}>
        <StrategicImportance image={bannerImage} />
        <DocumentaryAttestation image={cetateaSzathmary} />
        <ArchitecturalEvolution />
      </div>
    </div>
  );
};

export default FortressHistory;
